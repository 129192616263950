import { PatientDeleteModal } from "./patientDeleteModal"
import { useState } from "react";
import { MotionButton } from "../motion/MotionButton";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deletePatient } from "../../reducers/patient";

export const PatientSavings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [handleSubmit, setHandleSubmit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if(handleSubmit){
      dispatch(deletePatient())
    }
  }, [handleSubmit])
  return (
    <>
      <div className="col-sm-4 col-md-4 col-lg-4">
        <MotionButton>
          <button
            type="button"
            className="btn btn-primary forget_btn"
            onClick={() => setIsOpen(true)}
          >
            Delete
          </button>
        </MotionButton>
      </div>
      <PatientDeleteModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        submit={setHandleSubmit}
      />
    </>
  )
}