import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import cancel from "../../assets/images/RxLink assets/new assets/regular x.svg";
import { motion } from "framer-motion";
export const PatientDeleteModal = ({ isOpen, setIsOpen, submit }) => {

    return (

        <>
            {isOpen &&
                <>

                    <div className="modal show"
                        style={{ display: 'block', position: 'initial' }}>
                        <Modal.Dialog>
                            <Modal.Header >
                                <Modal.Title>Delete patient savings</Modal.Title>
                                <div className="x">
                                    <motion.button
                                        transition={{ type: "tween" }}
                                        whileTap={{
                                            scale: 0.95,
                                            transition: { duration: 0.1 },
                                        }}
                                        className="crossButton"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <img src={cancel} />
                                    </motion.button>
                                </div>
                            </Modal.Header>


                            <Modal.Body>
                                <p>Are you sure you want to delete this patient savings record?</p>
                            </Modal.Body>
                            <div className='row'>
                               
                                <div className="col-sm-6 col-md-6 col-lg-6" onClick={() => submit(true)}>
                                    <Button variant="primary">Continue</Button>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6" onClick={() => setIsOpen(false)}>
                                    <Button variant="secondary" >Cancel</Button>
                                </div>
                            </div>



                        </Modal.Dialog>
                    </div>
                </>
            }

        </>




    )
}