import React, { useEffect, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import cancelIcon from "./../../assets/images/RxLink assets/new assets/regular x.svg"
import { fetchMedicationDetail } from '../../reducers/medication';
import { useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';


const parseData = (data, selectedDrug) => {
    let names_array = []
    data.Value.map((med, index) => {
        names_array.push({ name: med.Key, type: med.Value[0].Value[0].Value[0].Value.IsGeneric ? "Generic" : "Brand" });
    })

    let forms_array = [];
    data.Value.map((drug, i) => {
        if (selectedDrug.name.toLowerCase() == drug.Key.toLowerCase()) {
            drug.Value.map((form, j) => {
                forms_array.push(form.Key);
            })
        }
    })

    let dosage_array = [];
    data.Value.map((drug, i) => {
        if (selectedDrug.name.toLowerCase() == drug.Key.toLowerCase()) {
            drug.Value.map((form, j) => {
                if (form.Key == selectedDrug.form) {
                    form.Value.map((dosage, k) => {
                        dosage_array.push(dosage.Key)
                    })
                }
            })
        }
    })

    let quantity_array = [];
    data.Value.map((drug, i) => {
        if (selectedDrug.name.toLowerCase() == drug.Key.toLowerCase()) {
            drug.Value.map((form, j) => {
                if (form.Key == selectedDrug.form) {
                    form.Value.map((dosage, k) => {
                        if (dosage.Key == selectedDrug.dosage) {
                            dosage.Value.map((quantity, i) => {
                                quantity_array.push(quantity.Key);
                            })
                        }
                    })
                }
            })
        }
    })


    return { names_array, forms_array, dosage_array, quantity_array }
}

function EditMedicationModal({
    isVisibleEditModal,
    setIsVisibleEditModal,
    selectedDrug,
    setSelectedDrug,
    savePrescription
}) {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [names, setNames] = useState([]);
    const [label, setLabel] = useState("");
    const [medDetail, setMedDetail] = useState(undefined);
    const [forms, setForms] = useState([]);
    const [form, setForm] = useState("");
    const [dosages, setDosages] = useState([]);
    const [dosage, setDosage] = useState("");
    const [quantities, setQuantities] = useState([]);
    const [quantity, setQuantity] = useState("");

    const onChangeLabel = (medName) => {
        setLabel(medName)
        medDetail.Value.map((drug, i) => {
            if (medName.toLowerCase() == drug.Key.toLowerCase()) {
                let drugForms = [];
                drug.Value.map((form, j) => {
                    drugForms.push(form.Key);
                })
                setForms(drugForms);
                setForm(drugForms[0]);

                onChangeForm(drugForms[0], medName);
            }
        })
    }

    const onChangeForm = (form, medicineName) => {
        setForm(form)
        medDetail.Value.map((drug, i) => {
            if ((medicineName && medicineName.toLowerCase() == drug.Key.toLowerCase()) || (!medicineName && label.toLowerCase() == drug.Key.toLowerCase())) {
                drug.Value.map((drugForm, j) => {
                    let drugDosages = [];
                    if (drugForm.Key == form) {
                        drugForm.Value.map((drugDosage, i) => {
                            drugDosages.push(drugDosage.Key)
                        })
                        setDosages(drugDosages);
                        setDosage(drugDosages[0]);

                        onChangeDosage(drugDosages[0], form)
                    }
                })

            }
        })
    }

    const onChangeDosage = (dosage, medicineForm) => {
        setDosage(dosage)
        medDetail.Value.map((drug, i) => {
            if (label == drug.Key) {
                drug.Value.map((drugForm, j) => {
                    if ((medicineForm && medicineForm == drugForm.Key) || (!medicineForm && form == drugForm.Key)) {
                        drugForm.Value.map((drugDosage, i) => {
                            let drugQuantities = [];
                            if (drugDosage.Key == dosage) {
                                drugDosage.Value.map((drugQuantity, i) => {
                                    drugQuantities.push(drugQuantity.Key);
                                })
                                setQuantities(drugQuantities);
                                setQuantity(drugQuantities[0])
                            }
                        })

                    }
                })

            }
        })
    }

    useEffect(() => {
        if (selectedDrug) {
            let user = JSON.parse(localStorage.getItem("user"));
            setLoading(true);
            dispatch(fetchMedicationDetail({ name: selectedDrug.name, appUserSeqNo: user.appUserSeqNo }))
                .unwrap()
                .then((response) => {
                    setLoading(false)
                    if (response) {
                        let { names_array, forms_array, dosage_array, quantity_array } = parseData(JSON.parse(response.data), selectedDrug);
                        setMedDetail(JSON.parse(response.data))
                        console.log({ names_array, forms_array, dosage_array, quantity_array });
                        let medLabel = names_array.filter((name) => name.name.toLowerCase() == selectedDrug.name.toLowerCase());
                        setLabel(medLabel.length > 0 ? medLabel[0].name : "");
                        setNames(names_array);
                        setForms(forms_array);
                        setForm(forms_array.filter((form) => form == selectedDrug.form)[0])
                        setDosages(dosage_array);
                        setDosage(dosage_array.filter((dosage) => dosage == selectedDrug.dosage)[0]);
                        setQuantities(quantity_array);
                        setQuantity(quantity_array.filter((quantity) => quantity == selectedDrug.default_quantity)[0]);
                    }
                })
                .catch((e) => {
                    setLoading(false)
                })
                .finally((e) => {
                    // resetDrugDetails();
                    setLoading(false)

                });
        }
        else {
            setNames(undefined);
            setLabel(undefined);
            // setMedDetail(undefined);
            setForms(undefined);
            setForm(undefined);
            setDosages(undefined);
            setDosage(undefined);
            setQuantities(undefined);
            setQuantity(undefined);
        }
    }, [selectedDrug])


    const submitForm = () => {
        let drugToEdit = null;

        medDetail.Value.map((drug, i) => {
            if (label.toLowerCase() == drug.Key.toLowerCase()) {
                drug.Value.map((drugForm, j) => {
                    if (drugForm.Key == form) {
                        drugForm.Value.map((drugDosage, i) => {
                            if (drugDosage.Key == dosage) {
                                drugDosage.Value.map((drugQuantity, i) => {
                                    if (drugQuantity.Key == quantity) {
                                        drugToEdit = drugQuantity.Value;
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
        savePrescription(drugToEdit, names, quantities);
    }


    return (
        <>
            {<Modal
                show={isVisibleEditModal}
                // onHide={() =>
                //     handleIsExpandable(obj)}
                keyboard={false}
                centered
            >
                <Modal.Header className="container">
                    <Modal.Title>
                        Edit medication
                    </Modal.Title>
                    <button
                        className="crossButton"
                        onClick={() => {
                            setSelectedDrug(undefined)
                            setIsVisibleEditModal(false);
                        }}
                    >
                        <img src={cancelIcon} alt="Close popup" />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <div style={{ position: "absolute", top: "50%", left: "50%", zIndex: 1001 }}><Spinner animation="border" variant="primary" /></div>
                        : <section className="editModel">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <select
                                        className="form-select"
                                        value={label}
                                        onChange={(e) => onChangeLabel(e?.target?.value)}

                                    >
                                        {
                                            names?.map((option, i) => {
                                                return (
                                                    <option
                                                        value={option?.name}
                                                    >
                                                        {option?.name} (
                                                        {option?.type})
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <select
                                        className="form-select"
                                        value={form}
                                        onChange={(e) => onChangeForm(e?.target?.value)}
                                    >
                                        {
                                            forms?.map((form, i) => {
                                                return (
                                                    < option
                                                        value={form}
                                                    >
                                                        {form}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <select
                                        className="form-select"
                                        value={dosage}
                                        onChange={(e) => onChangeDosage(e?.target?.value)}
                                    >
                                        {
                                            dosages?.map((dosage, i) => {
                                                return (
                                                    <option
                                                        value={dosage}
                                                    >
                                                        {dosage}
                                                    </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <select
                                        className="form-select"
                                        value={quantity}
                                        onChange={(e) => setQuantity(e?.target?.value)}
                                    >
                                        {quantities?.map(
                                            (opt, i) => {
                                                return (
                                                    <option
                                                        value={opt}
                                                    >
                                                        {opt}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <button
                                        className="btn_success"
                                        onClick={() => submitForm()}
                                    >
                                        Confirm
                                    </button>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <button
                                        className="btn_default"
                                        onClick={() => {
                                            setSelectedDrug(undefined)
                                            setIsVisibleEditModal(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </section>}
                </Modal.Body>
            </Modal >
            }
        </>
    )
}

export default EditMedicationModal