import InputMask from "react-input-mask";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import validator from "validator";

const phoneNumberMask = "(999) 999-9999"

export const UsernameField = ({ formik, mask, setMask, validateSubmit }) => {
    const [username, setUsername] = useState("");
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [isEmailValue, setEmailValue] = useState("");
    const [emailError, setEmailError] = useState("");
    

    useEffect(() => {
      //  const text = formik.values.username.replace("(", "").replace(")", "").replace(" ", "").replace("-", "")
        // if (parseInt(text) && text.length < 11) {
          //  setMask(phoneNumberMask)
        // } else {
        //     setMask("")
        // }
    }, [formik.values.username])

    useEffect(()=>{
        if(validateSubmit){
            validateEmail(username, true)
        }
    },[validateSubmit])

    useEffect(() => {
        formik.setFieldValue("username", username);
    }, [username])


    useEffect(() => {
        setTimeout(() => formik.validateForm(), 200)
    }, [mask])

    const onUsernameChange = (e) => {
        const key = e.key
        const text = e.target.value
        setUsername(text);
    }

    const validateEmail = (e, flag) => {
        var email = !flag ? e.target.value : e;
        setUsername(email);
        if(!email){
            setEmailError("Email is required");
            return
        }
        if (validator.isEmail(email)) {
          setEmailError("");
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
          setEmailError("Enter valid email");
        }
      };

    return (
        <div className="row g-0">
            <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="mb-1 form-group">
                <input
                        name="username"
                        className={
                            "form-control custom-form-control login_input" 
                        }
                        id="floatingEmail"
                        type="email"
                        onChange={(e) => validateEmail(e)}
                        placeholder="Email"
                    />
                    <div>
                    <span className="emailError">{emailError}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const usernameValidation = 
// (mask) => mask ? 
Yup.string()
    .required("Email is required")
    .email("Invalid email address")
    // .test('phone-number-length', 'Invalid phone number', (value) => {
    //     // Remove non-numeric characters to check the length
    //     const numericValue = value.replace(/\D/g, '');
    //     return numericValue.length === 10;
    // })

    // : Yup.string()
    //     .required("Phone number / Email address is required")
    //     .email("Invalid email address")