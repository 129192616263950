import Modal from "react-bootstrap/Modal";
import { MotionButton, MotionIcon } from "../../motion/MotionButton";
import InputMask from "react-input-mask";
import { motion, AnimatePresence } from "framer-motion";
import PaperPlane from "../../../assets/images/paper-plane.svg";
import PaperPlaneM from "../../../assets/images/paper-plane-m.svg";
import { useStyleMediaQuery } from "../hooks";
import { useEffect } from "react";
import cancel from "./../../../assets/images/RxLink assets/new assets/regular x.svg"



export const SendSMSOrEmailModalTab = ({ isOpen, formik, handleClose, isSMS, sendEmail, sendSMS, validateEmail, emailError, hasSuccess, termsHandler, termsError }) => {
    const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 500 });


    return (
        <Modal show={isOpen} onHide={handleClose} keyboard={false} centered>
            <Modal.Body className="coupon_popup_tab">
                <div className="row x">
                    <motion.button
                        transition={{ type: "tween" }}
                        whileTap={{
                            scale: 0.95,
                            transition: { duration: 0.1 },
                        }}
                        className="crossButton"
                        onClick={handleClose}
                    >
                        <img src={cancel} />
                    </motion.button>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0 autoMargin">
                    <div className="paper-plane-image  mt-4 ">
                        <motion.img
                            key={isMobile ? PaperPlaneM : PaperPlane}
                            exit={{ opacity: 0 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            src={isMobile ? PaperPlaneM : PaperPlane}
                            transition={{ duration: 1, ease: 'easeIn' }}
                            className="img-fluid"
                        />
                    </div>
                </div>
                {hasSuccess ?
                    <>
                        <section className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3 p-0 inner">
                            <div className="container">
                                <form
                                    id="loginID"
                                    className=" "
                                >
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 pt-3">
                                        <h1 style={{ fontWeight: '700', fontSize: 'calc(0.975rem + 1.5vw)' }}>Coupon Sent</h1>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <p style={{ fontSize: '0.9rem' }}>
                                                Your personalized medication savings plan has
                                                been sent to your designated contact
                                            </p>
                                        </div>
                                        {
                                            !isMobile &&
                                            <div className="row mt-2 mb-4">
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                    <div className="">
                                                        <MotionButton>
                                                            <button
                                                                type="button"
                                                                className="btn_default"
                                                                onClick={handleClose}
                                                            >
                                                                Close
                                                            </button>
                                                        </MotionButton>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </form>
                            </div>
                        </section>
                        {
                            isMobile &&
                            <div className="row mt-2 mb-4">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="">
                                        <MotionButton>
                                            <button
                                                type="button"
                                                className="btn_default"
                                                onClick={handleClose}
                                            >
                                                Close
                                            </button>
                                        </MotionButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <>
                        <section className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3 pt-1 inner">
                            <div className="container">
                                <form
                                    onSubmit={formik.handleSubmit}
                                    id="loginID"
                                    className=" "
                                >
                                    <div className="row mt-3">
                                        <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                                            <h4 className="phone_popup_heading mt-2">
                                                Enter your patient's {isSMS ? "phone number" : "email"}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="hrDiv hrDivpadding"></div>
                                    </div>

                                    {
                                        isSMS ?
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <InputMask
                                                        name="username"
                                                        className={
                                                            "form-control custom-form-control" +
                                                            (formik.errors.username && formik.touched.username
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        id="floatingInputPhone"
                                                        mask="(999) 999-9999"
                                                        maskChar={null}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.username}
                                                        placeholder="Enter your patient's phone number"
                                                        type="tel"
                                                    ></InputMask>
                                                    <div className="invalid-feedback">
                                                        {formik.errors.username && formik.touched.username
                                                            ? formik.errors.username
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <input
                                                        type="email"
                                                        className="form-control text_input_insurance"
                                                        id="userEmail"
                                                        placeholder="your@email.com"
                                                        onChange={(e) => validateEmail(e)}
                                                    />
                                                    <span className="emailError">{emailError}</span>
                                                </div>
                                            </div>
                                    }
                                    {
                                        !isMobile &&
                                        <div className="row">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                <div className="mt-20">
                                                    <MotionButton>
                                                        <button
                                                            className="btn_success"
                                                            onClick={isSMS ? sendSMS : sendEmail}>
                                                            Send coupon
                                                        </button>
                                                    </MotionButton>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                <div className="mt-20">
                                                    <MotionButton>
                                                        <button
                                                            type="button"
                                                            className="btn_default"
                                                            onClick={handleClose}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </MotionButton>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row mt-2 mb-2">
                                        {/* <div style={{ width: "5%" }}>
                                            <MotionIcon>
                                                <input type="checkbox" name='terms' className="p-1 mr-2" onChange={(e) => termsHandler(e)} />
                                            </MotionIcon>
                                        </div> */}
                                        {/* <div className="pl-0" style={{ width: "85%", marginTop: "5px", marginLeft: "5px" }}>
                                            {
                                                termsError ?
                                                    <h5 className="phone_popup_error">
                                                        I agree to receiving alerts related to personalized
                                                        medication savings and refill reminders. Standard
                                                        rates may apply.
                                                    </h5>
                                                    :
                                                    <h5 className="phone_popup">
                                                        I agree to receiving alerts related to personalized
                                                        medication savings and refill reminders. Standard
                                                        rates may apply.
                                                    </h5>
                                            }
                                        </div> */}
                                    </div>
                                </form>
                            </div>
                        </section>
                        {
                            isMobile &&
                            <div className="row">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="mt-20">
                                        <MotionButton>
                                            <button
                                                className="btn_success"
                                                onClick={isSMS ? sendSMS : sendEmail}>
                                                Send coupon
                                            </button>
                                        </MotionButton>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="mt-20">
                                        <MotionButton>
                                            <button
                                                type="button"
                                                className="btn_default"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </button>
                                        </MotionButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
            </Modal.Body>
        </Modal>
    )
}


export const SendSMSOrEmailModalWeb = ({ isOpen, formik, handleClose, isSMS, sendEmail, sendSMS, validateEmail, emailError, hasSuccess, termsHandler, termsError }) => {
    const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 900 });

    useEffect(() => {
        if (isMobile) {
            handleClose()
        }
    }, [isMobile])

    return (
        <Modal show={isOpen} onHide={handleClose} keyboard={false} centered>
            <Modal.Body className="coupon_popup">
                <div className="row x">
                    <motion.button
                        transition={{ type: "tween" }}
                        whileTap={{
                            scale: 0.95,
                            transition: { duration: 0.1 },
                        }}
                        className="crossButton"
                        onClick={handleClose}
                    >
                        <img src={cancel} />
                    </motion.button>
                </div>
                <div className="row c_row">
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5 p-0 autoMargin">
                        <div className="paper-plane-image  mt-4 ">
                            <motion.img
                                key={isMobile ? PaperPlaneM : PaperPlane}
                                exit={{ opacity: 0 }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                src={isMobile ? PaperPlaneM : PaperPlane}
                                transition={{ duration: 1, ease: 'easeIn' }}
                                className="img-fluid"
                            />
                        </div>
                    </div>
                    {hasSuccess ?
                        <section className="col-7 col-sm-7 col-md-7 col-lg-7 p-0 autoMargin inner">
                            <div className="container">
                                <form
                                    id="loginID"
                                    className=" "
                                >
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                        <h1 style={{ fontWeight: '700', fontSize: 'calc(0.975rem + 1.5vw)' }}>Coupon Sent</h1>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <p style={{ fontSize: '0.9rem' }}>
                                                Your personalized medication savings plan has
                                                been sent to your designated contact
                                            </p>
                                        </div>
                                        <div className="row mt-2 mb-4">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                <div className="">
                                                    <MotionButton>
                                                        <button
                                                            type="button"
                                                            className="btn_default"
                                                            onClick={handleClose}
                                                        >
                                                            Close
                                                        </button>
                                                    </MotionButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </section>
                        :
                        <section className="col-7 col-sm-7 col-md-7 col-lg-7 p-0 autoMargin inner">
                            <div className="container">
                                <form
                                    onSubmit={formik.handleSubmit}
                                    id="loginID"
                                    className=" "
                                >
                                    <div className="row mt-3">
                                        <div className="col-10 col-sm-10 col-md-10 col-lg-10">
                                            <h4 className="phone_popup_heading mt-2">
                                                Enter your patient's {isSMS ? "phone number" : "email"}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="hrDiv hrDivpadding"></div>
                                    </div>

                                    {
                                        isSMS ?
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <InputMask
                                                        name="username"
                                                        className={
                                                            "form-control custom-form-control" +
                                                            (formik.errors.username && formik.touched.username
                                                                ? " is-invalid"
                                                                : "")
                                                        }
                                                        id="floatingInputPhone"
                                                        mask="(999) 999-9999"
                                                        maskChar={null}
                                                        onChange={formik.handleChange}
                                                        value={formik.values.username}
                                                        placeholder="Enter your patient's phone number"
                                                        type="tel"
                                                    ></InputMask>
                                                    <div className="invalid-feedback">
                                                        {formik.errors.username && formik.touched.username
                                                            ? formik.errors.username
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <input
                                                        type="email"
                                                        className="form-control text_input_insurance"
                                                        id="userEmail"
                                                        placeholder="your@email.com"
                                                        onChange={(e) => validateEmail(e)}
                                                    />
                                                    <span className="emailError">{emailError}</span>
                                                </div>
                                            </div>
                                    }
                                    <div className="row">
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="mt-20">
                                                <MotionButton>
                                                    <button
                                                        className="btn_success"
                                                        onClick={isSMS ? sendSMS : sendEmail}>
                                                        Send coupon
                                                    </button>
                                                </MotionButton>
                                            </div>
                                        </div>
                                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            <div className="mt-20">
                                                <MotionButton>
                                                    <button
                                                        type="button"
                                                        className="btn_default"
                                                        onClick={handleClose}
                                                    >
                                                        Cancel
                                                    </button>
                                                </MotionButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-2"></div>
                                    {/* <div className="row mt-2 mb-2">
                                        <div style={{ width: "5%" }}>
                                            <MotionIcon>
                                                <input type="checkbox" name='terms' className="p-1" onChange={(e) => termsHandler(e)} />
                                            </MotionIcon>
                                        </div>
                                        <div className="pl-0" style={{ width: "93%", marginTop: "5px", marginLeft: "5px" }}>
                                            {
                                                termsError ?
                                                    <h5 className="phone_popup_error">
                                                        I agree to receiving alerts related to personalized
                                                        medication savings and refill reminders. I also agree to the <a href="https://rxlink.com/terms/" target="_blank">Terms of Service</a>. Standard
                                                        rates may apply.
                                                    </h5>
                                                    :
                                                    <h5 className="phone_popup">
                                                        I agree to receiving alerts related to personalized
                                                        medication savings and refill reminders. I also agree to the <a href="https://rxlink.com/terms/" target="_blank">Terms of Service</a>. Standard
                                                        rates may apply.
                                                    </h5>
                                            }
                                        </div>
                                    </div> */}
                                </form>
                            </div>
                        </section>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}