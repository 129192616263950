import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
  medicationResponse: {}
};

export const addOrUpdateMedi = (requsetBody, callback) => {
  httpClientJava.post("medication/addOrUpdateMedication", requsetBody)
  .then((res)=>{
      if(callback) callback(res)
  })
  .catch((err)=>{
      console.log(err);
  })

}

export const addOrUpdateMedication = createAsyncThunk("addOrUpdateMedication", (body) => {
  httpClientJava
    .post(`medication/addOrUpdateMedication`, body)
    .then((data) => {
      if(data){
        return data;
      }
    })
    .catch(error => error)

})

export const addOrUpdateMedicationData = createSlice({
  name: "addOrUpdateMedication",
  initialState,
  reducers: {
    setaddOrUpdateMedicationData: (state, action) => {
      state.medicationResponse = action.payload;
    },
    [addOrUpdateMedication.pending.type]: (state, action) => {
      state.medicationResponse = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addOrUpdateMedication.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
      state.medicationResponse = {
        status: "idle",
        data: action.payload,
        error: {},
      };
    },
    [addOrUpdateMedication.rejected.type]: (state, action) => {
      state.medicationResponse = {
        status: "idle",
        data: {},
        error: action.payload,
      };
    },
  },
});

export const { setaddOrUpdateMedicationData } = addOrUpdateMedicationData.actions;

export default addOrUpdateMedicationData.reducer;