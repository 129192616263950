// import { pharmacyTypes } from "./PharmacyTypes";
export function filterPharmacyNames(selectedPharmacy, pharmacyTypes) {
    var pharmacyNameWithoutPostfix = selectedPharmacy;
    //selectedPharmacy.replace(/Pharmacy$/i, "").trim();
    if (pharmacyNameWithoutPostfix?.toLowerCase() == "h-e-b") pharmacyNameWithoutPostfix = "HEB";
    const isMatchingType = pharmacyTypes?.some((pharmacyType) => pharmacyNameWithoutPostfix?.toLowerCase() == pharmacyType?.toLowerCase()
        // pharmacyNameWithoutPostfix?.toLowerCase()?.includes(pharmacyType?.toLowerCase())
    );
    if (isMatchingType) {
        return { name: pharmacyNameWithoutPostfix, type: pharmacyNameWithoutPostfix };
    } else {
        return { name: pharmacyNameWithoutPostfix, type: "Local Pharmacies" };
    }
}