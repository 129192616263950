import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  ip: '',
  ownPosition: null,
  rxSenseToken : null,
  auth: null,
  insuranceInfo: null,
  loadingPharmacy: false,
};

export const global = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      console.log({ action: action.payload });
      state.loader = action.payload;
    },
    setLoadingPharmacy: (state, action) => {
      state.loadingPharmacy = action.payload;
    },
    setIP: (state, action) => {
      state.ip = action.payload;
    },
    setOwnPosition: (state, action) => {
      state.ownPosition = action.payload;
    },
    setRxSenseToken: (state, action) => {
      state.rxSenseToken = action.payload;
    },
    setAuth: (state, action) => {
      state.auth = action.payload;
    },
    setInsuranceInfo: (state, action) => {
      state.insuranceInfo = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const { setLoader, setIP, setOwnPosition, setRxSenseToken, setAuth, setInsuranceInfo, setLoadingPharmacy } = global.actions;

export default global.reducer;