
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputMask from 'react-input-mask';
import { MotionButton } from "../motion/MotionButton";
import cancelIcon from "./../../assets/images/RxLink assets/new assets/regular x.svg";
import { UsernameField, usernameValidation } from "../Fields/Username";

export default (props) => {
  const [mask, setMask] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(props.show);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isOpenResetPwdModal, setIsOpenResetPwdModal] = useState(false);
  var numberPattern = /\d+/g;
  useEffect(() => {
    // console.log(modalIsOpen);
  }, [modalIsOpen]);

  const changePasswordType = () => {
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  const changeConfirmPasswordType = () => {
    setConfirmPasswordType(
      confirmPasswordType == "password" ? "text" : "password"
    );
  };

  const validationSchema = Yup.object().shape({
    username: usernameValidation,
  });

  const formik = useFormik({
    initialValues: {
      username: "",

    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      let body = {
        number: data.username,
      };
      props.setSubmitForgotPasswordData(body);
    },
  });

  const setResetPwd = (value) => {
    setIsOpenResetPwdModal(value);
  }

  return (
    <>
      <Modal show={props.show} onHide={props.close} keyboard={false} centered>
        {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <section className="forget_section">
            <div className="container">
              <form onSubmit={formik.handleSubmit} id="resetPasswordForm">
                <div className="forget_box">
                  {/* <div className="row x">
                    <div className="text-end">
                      <button
                        className="crossButton"
                        onClick={props.close}
                        type="button"
                      >
                        <img src={cancelIcon} />
                      </button>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <p>
                        Forgot Password
                      </p>
                    </div>
                  </div>
                  <UsernameField
                    formik={formik}
                    mask={mask}
                    setMask={setMask} />

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="submit"
                          className="btn_success "
                          form="resetPasswordForm" onClick={() => setResetPwd(true)}
                        >
                          Submit
                        </button>
                      </MotionButton>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <MotionButton>
                          <button
                            type="button"
                            className="btn_default mt-2"
                            onClick={props.close}
                          >
                            Cancel
                          </button>
                        </MotionButton>
                      </div>
                    </div>
                  </div>
                  <div className="row">

                  </div>
                </div>
              </form>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};
