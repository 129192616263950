import { useState, useRef, useEffect } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";

const initialState = {
    month: NaN,
    day: NaN,
    year: NaN
}

const DateOfBirth = ({ formik, fieldName, enabled, patientRecordDob }) => {
    const pickerRef = useRef(null)
    const [error, setError] = useState(null);
    const [manualDate, setManualDate] = useState(initialState);

    useEffect(() => {
        if (pickerRef.current && pickerRef.current.input)
            pickerRef.current.input.readOnly = true;
    }, [pickerRef])

    const handleManualChange = (e) => {
        const { name, value } = e.target;
        const newValue = parseInt(value);
        let error = false
        switch (name) {
            case "month":
                if (newValue > 12) {
                    setError("Please check that you are inputting a valid month and try again.")
                    error = true
                    break;
                }
                setError(null)
                break;
            case "day":
                if (newValue > 31) {
                    setError("Please check that you are inputting a valid day and try again.")
                    error = true
                    break;
                }
                setError(null)
                break;
            case "year":
                if ((newValue < 1900 || newValue > new Date().getFullYear()) && value.length >= 4) {
                    setError("Please check that you are inputting a valid year and try again.")
                    error = true
                    break;
                }
                setError(null)
                break;
            default:
                break;
        }

        if (!error) {
            const newManualDate = { ...manualDate, [name]: value }
            const { month, day, year } = newManualDate;
            if (Number(month) && Number(day) && Number(year) && year.length === 4) {
                const date = new Date(year, month - 1, day);
                formik.setFieldValue(fieldName, date)
            } else {
                formik.setFieldValue(fieldName, "")
            }
            setManualDate(newManualDate)
        } else {
            formik.setFieldValue(fieldName, "")
            setManualDate(initialState);
        }
    }

    const addleadingZero = (value) => {
        if (value.length === 1) {
            return "0" + value
        }
        return value
    }

    const handleCalendarDateChange = (date) => {
        const newManualDate = { month: addleadingZero(String(date.getMonth() + 1)), day: addleadingZero(String(date.getDate())), year: String(date.getFullYear()) }
        setManualDate(newManualDate)
    }

    const CustomInput = forwardRef((props) => {
        return (
            <FontAwesomeIcon icon={faCalendarDays} onClick={props.onClick} style={{ fontSize: 30, cursor: enabled && 'pointer' }} />
        );
    });

    useEffect(() => {
        if (formik.values[fieldName]) {
            if (patientRecordDob && patientRecordDob instanceof Date && !equalDates(patientRecordDob, formik.values[fieldName])) {
                setError("Please check that you are inputting your date of birth in MM/DD/YYYY format and try again.")
                setManualDate(initialState);
                return;
            }
            handleCalendarDateChange(formik.values[fieldName])
            setError(null)
        }
    }, [formik.values[fieldName]])

    const equalDates = (date1, date2) => {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear()
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '1.5px', marginBottom: '4px' }}>
                <LabeledInput
                    label={"Month"}
                    fieldName="month"
                    className={
                        `form-control  custom-form-control ${!enabled && "disabled-input"}` +
                        ((formik.errors[fieldName] && formik.touched[fieldName]) || error
                            ? " is-invalid"
                            : "")
                    }
                    placeholder="MM"
                    onChange={handleManualChange}
                    value={manualDate.month}
                    disabled={!enabled}
                />
                <LabeledInput
                    label={"Day"}
                    fieldName="day"
                    className={
                        `form-control  custom-form-control ${!enabled && "disabled-input"}` +
                        ((formik.errors[fieldName] && formik.touched[fieldName]) || error
                            ? " is-invalid"
                            : "")
                    }
                    placeholder="DD"
                    onChange={handleManualChange}
                    value={manualDate.day}
                    disabled={!enabled}
                />
                <LabeledInput
                    label={"Year"}
                    fieldName="year"
                    className={
                        `form-control  custom-form-control ${!enabled && "disabled-input"}` +
                        ((formik.errors[fieldName] && formik.touched[fieldName]) || error
                            ? " is-invalid"
                            : "")
                    }
                    placeholder="YYYY"
                    onChange={handleManualChange}
                    value={manualDate.year}
                    disabled={!enabled}
                />
                <div style={{ width: "8%" }}>
                    {enabled && <DatePicker
                        ref={pickerRef}
                        name={fieldName}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        minDate={new Date(1900, 0, 1)}
                        maxDate={new Date()}
                        className={
                            `form-control  custom-form-control ${!enabled && "disabled-input"}` +
                            (formik.errors[fieldName] && formik.touched[fieldName]
                                ? " is-invalid"
                                : "")
                        }
                        customInput={<CustomInput />}
                        onChange={date => {
                            formik.setFieldValue(fieldName, date)
                        }}
                        selected={formik.values[fieldName]}
                        placeholderText="Date of Birth*"
                        disabled={!enabled}
                    />}
                </div>
            </div>
            <div className="date-picker-feedback invalid-feedback">
                {" "}
                {formik.errors[fieldName] && formik.touched[fieldName] && !error && enabled
                    ? formik.errors[fieldName]
                    : null}{" "}
                {error && enabled ? error : null}
            </div>
        </>
    );
}

export default DateOfBirth;


const LabeledInput = ({ fieldName, className, placeholder, onChange, value, disabled, label }) => {
    return (
        <div style={{ width: "27%", position: 'relative' }}>
            <label htmlFor={fieldName} className="input-with-label">{label}</label>
            <input
                type="number"
                inputmode="decimal"
                name={fieldName}
                className={className}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                disabled={disabled}
            /></div>
    );
};