import axios from "axios";
import { store } from "./store";
// export const baseUrl = `https://api.hippo-stage.tools/v2/`; //env == "dev" ? `http://localhost:5000/api` : `https://techovaruas-backend.vercel.app/api`;
// export const baseUrl = `https://gateway.hippo.tools/`
export const baseUrlRxSense = `https://uatapi-gateway.rxsense.com/`
export const baseUrlHippo = `https://api.hellohippo.com/V2/`
const requestHeaderHippo = {
  // Accept: "*/*",
  "Content-Type": "application/json",
  // "api_key": "ZAbRkXksLxpPnNMemkcRBkRhZZkdTRHu7" //Staging
  "api_key": process.env.REACT_APP_HIPPO_KEY 
};

export const httpClient = axios.create({
  baseURL: baseUrlHippo, //YOUR_API_URL HERE
  headers: requestHeaderHippo,
  // withCredentials:true
});

export const formData = new URLSearchParams();
formData.append('client_id', '198');
formData.append('client_secret', '97EPB535G841');


export const httpClientRxSense = axios.create({
  baseURL: baseUrlRxSense,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
})

export const GoogleAPIKeyClient = process.env.REACT_APP_GOOGLE_API_KEY;

export const IPAPIkey = process.env.REACT_APP_IPAPI_KEY;


// export const domainName = "Upenn";
// export const domainName = "PapaJohns";
// export const domainName = "Tripment";
export const domainName = "RxLink";



// export const PID = "UPENN";
// export const PID = "PAPAJOHNS";
// export const PID = "TRIPMENT";
export const PID = "RXLINK";


// export const baseUrlJava = `https://devapi.rxlink.com/API/v1/` // dev URL
export const baseUrlJava = process.env.REACT_APP_BASE_URL_JAVA// staging URL
// export const baseUrlJava = `https://productionapi-0001.rxlink.com/clinic/v1/` // production URL

export const baseUrlJavaRxSense = process.env.REACT_APP_BASE_URL_JAVA_RXSENSE// staging RxSense
// export const baseUrlJavaRxSense = `https://productionapi-0001.rxlink.com/api/v1/` // production RxSense

export const baseUrlConnective = process.env.REACT_APP_BASE_URL_CONNECTIVE// staging RxSense




const requestHeaderJava = {
  // Accept: "*/*",
  "Content-Type": "application/json",

};

export const httpClientJava = axios.create({
  baseURL: baseUrlJava, //YOUR_API_URL HERE
  headers: requestHeaderJava,
  // withCredentials:true
});

export const httpClientJavaRxSense = axios.create({
  baseURL: baseUrlJavaRxSense, //YOUR_API_URL HERE
  headers: requestHeaderJava,
  // withCredentials:true
});

export const httpClientConnective = axios.create({
  baseURL: baseUrlConnective, //YOUR_API_URL HERE
  headers: requestHeaderJava,
  // withCredentials:true
});

httpClientJava.interceptors.request.use(
  config => {
    if (!(config?.url === "patient/login") && !(config.url.includes("patient/forgetPassword?username=")) && !(config.url.includes("signUp")) && !(config.url.includes("patient/validateOtp")) && !(config.url.includes("patient/generateOtp"))) {
      const token = JSON.parse(localStorage.getItem('user')).token;
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

httpClientJavaRxSense.interceptors.request.use(
  config => {
    if (!(config?.url === "patient/login") && !(config.url.includes("patient/forgetPassword?username=")) && !(config.url.includes("signUp")) && !(config.url.includes("patient/validateOtp")) && !(config.url.includes("patient/generateOtp"))) {
      const token = JSON.parse(localStorage.getItem('user')).token;
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

httpClientConnective.interceptors.request.use(
  config => {
    if (!(config?.url === "patient/login") && !(config.url.includes("patient/forgetPassword?username=")) && !(config.url.includes("signUp")) && !(config.url.includes("patient/validateOtp")) && !(config.url.includes("patient/generateOtp"))) {
      const token = JSON.parse(localStorage.getItem('user')).token;
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    Promise.reject(error)
  }
);

// httpClientRxSense.interceptors.request.use(
//   config => {
//     if (!(config?.url === "account/token")) {
//       // debugger;
//       const reduxState = store.getState();
//       const rxSenseToken = reduxState.global.rxSenseToken;
//       // debugger;
//       // const token = JSON.parse(localStorage.getItem('user')).token;
//       if (rxSenseToken) {
//         config.headers['Authorizer'] = rxSenseToken;
//       }
//       config.headers['Content-Type'] = 'application/json';

//     }
//     return config;
//   },
//   error => {
//     Promise.reject(error)
//   }
// );

httpClientJava.interceptors.response.use((response) => {
  // if (response?.data?.statusCode === 412) {
  //   const originalRequest = response.config;
  //   const userDetail = JSON.parse(localStorage.getItem('user'));
  //   return httpClientJava.get('patient/refreshToken', {
  //     headers: { "Authorization": userDetail?.refreshToken, "Content-Type": "application/json" }
  //   }).then(res => {
  //     if (res?.data) {
  //        userDetail.token = [...res?.data?.token];
  //        userDetail.refreshToken = [...res?.data?.refreshToken];
  //       localStorage.setItem('user', JSON.stringify(userDetail));
  //       httpClientJava.defaults.headers.common['Authorization'] = 'Bearer' + res?.data?.token;
  //       return httpClientJava(originalRequest);
  //     }
  //   })
  // }
  return response
},
  function (error) {

    return Promise.reject(error);
  }
);

httpClientJavaRxSense.interceptors.response.use((response) => {
  // if (response?.data?.statusCode === 412) {
  //   const originalRequest = response.config;
  //   const userDetail = JSON.parse(localStorage.getItem('user'));
  //   return httpClientJava.get('patient/refreshToken', {
  //     headers: { "Authorization": userDetail?.refreshToken, "Content-Type": "application/json" }
  //   }).then(res => {
  //     if (res?.data) {
  //        userDetail.token = [...res?.data?.token];
  //        userDetail.refreshToken = [...res?.data?.refreshToken];
  //       localStorage.setItem('user', JSON.stringify(userDetail));
  //       httpClientJava.defaults.headers.common['Authorization'] = 'Bearer' + res?.data?.token;
  //       return httpClientJava(originalRequest);
  //     }
  //   })
  // }
  return response
},
  function (error) {

    return Promise.reject(error);
  }
);