import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    LoggedInStatus : {}
  };
  

export const login = createAsyncThunk("Login", (body) =>
    httpClientJava
    .post(`patient/login`, body)
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);

export const loggedIn = createSlice({
    name: "loggedIn",
    initialState,
    // reducers: {
      
    // },
    reducers: {
      setUserData: (state, action) => {
        state.LoggedInStatus = action.payload;
      },
        [login.pending.type]: (state, action) => {
          state.LoggedInStatus = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [login.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.LoggedInStatus = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [login.rejected.type]: (state, action) => {
          state.LoggedInStatus = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setUserData } = loggedIn.actions;

export default loggedIn.reducer;