import { useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MotionIcon } from "./motion/MotionButton";
import { Divider } from "./searchPharmacy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faGreaterThan from "../assets/images/RxLink assets/new assets/arrow/side.svg"
// import { faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import { setShowPahrmacyMap, setResetPwdModal,setFromDrawer, setShowCoupon } from "../reducers/medication";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useState } from "react";
import { MotionButton } from "./motion/MotionButton";
import { useGetUser } from "../utilities/useGetUser";

const Drawer = ({totalSavings}) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const user = JSON.parse(localStorage.getItem("user"));
  const user = useGetUser();


  // const [totalSavings, setTotalSavings] = useState(0);
  // const [savingsToRender, setSavingToRender] =  useState([])


  const pharmacyList = useSelector((state) => state.medication.pharmacyList);
  const selectedPharmacy = pharmacyList.length > 0 ? pharmacyList.filter(item => !!item.isDefault) : [];

  const savings = useSelector((state) => state.medication.savingHistory);

  // useEffect(() => {
        
  //   if (!savings || savings.status !== "OK" || !savings.data || savings.data.length === 0) {
  //     return;
  //   }
  //   const filledSavings = savings.data.filter(item => item.claim_Status === "filled");
  //   if (filledSavings.length === 0) {
  //     return;
  //   }
  
  //   const savingsData = filledSavings.reduce((accumulator, saving, index) => {
  //     const existingIndex = accumulator.findIndex(el => el.dateFilled === saving.date_filled);
  
  //     if (existingIndex !== -1) {
  //       // Update existing entry.
  //       accumulator[existingIndex].drugsName += `, ${saving.drugName}`;
  //       accumulator[existingIndex].price += saving.copay_amount;
  //     } else {
  //       // Create a new entry.
  //       accumulator.push({
  //         id: index,
  //         drugsName: saving.drugName,
  //         dateFilled: saving.date_filled,
  //         price: saving.copay_amount,
  //       });
  //     }
  
  //     return accumulator;
  //   }, []);
  
  //   setSavingToRender(savingsData);
  // }, [savings]);

  // const reduxState = store.getState();

  // console.log({selectedPharmacy});

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    dispatch(setShowCoupon(false))
    navigate("/");
  }

  const searchPharmacy = () => {
    navigate("/searchPharmacy");
  }
  // console.log({ savingsToRender })

  // useEffect(() => {
  //   console.log({ savingsToRender })
  //   if (savingsToRender.length > 0 && totalSavings == 0) {

  //     savingsToRender.map((el, i) => {
  //       setTotalSavings(prev => prev + el.price)
  //     })
  //   }
  // }, [savingsToRender])

  useEffect(() => {
    console.log({ totalSavings })
  }, [totalSavings])

  const isCoupon = window.location.href.includes("coupon");

  return (
    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div className="offcanvas-header">
        <div className="medicine_icon pharma_icon back_icon my-4 mx-0"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <MotionIcon><i className="fas fa-angle-right"></i></MotionIcon>
        </div>
      </div>
      <div className="offcanvas-body" style={{marginTop: -20}}>
        <div className="right-aligned-item"> <span style={{fontSize: 27, fontWeight: "bold", color: "rgba(0, 0, 0, 0.7)"}}>Hello {user?.firstName}</span> </div>
        {totalSavings > 0 && <div className="right-aligned-item" > <span>You have saved <strong>$</strong> <span className="bold-text">{totalSavings.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</span></span> </div>}
        <Divider />
        <div style={{ margin: "18px 0px 0px 0px" }}>
          {/* <DrawerItem title="My account" iconToRender={faGreaterThan} /> */}
         
          <DrawerItem title="Patient savings history" iconToRender={faGreaterThan} onClickHandler={() => navigate("/savings")} />
          <DrawerItem title="New patient savings" iconToRender={faGreaterThan} onClickHandler={() => navigate("/patient")} />
        </div>
        <div className="logout-item">
          <DrawerItem title="Sign out" onClickHandler={logout} />
        </div>
      </div>
    </div>
  )
}

export default Drawer;


const DrawerItem = ({ title, iconToRender, subText, onClickHandler }) => {

  const ref = useRef(null);

  useLayoutEffect(() => {
    ref.current.style.setProperty("max-width", "100%", "important");
  }, []);

  return (
    <MotionButton>
      <button className="btn_default text-start"
        ref={ref}
        onClick={() => {
          if (!!onClickHandler) {
            onClickHandler();
          }
        }}
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"
        whileTap={{ transition: { type: "tween", duration: 0.1 }, scale: 0.95 }}
      >
        <div className="row">
          <span className="col-10 fw-bold">
            {title}
            {!!subText && <br />} {!!subText && <span style={{ fontSize: 12 }} className="fw-normal">{subText}</span>}
          </span>
          {!!iconToRender && <span className="col-2 icon-forward"
          >
            <i>
              <img src={faGreaterThan} />
            </i>
          </span>}
        </div>
      </button>
    </MotionButton>
  )
}