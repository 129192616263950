import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { saveInsurance } from "../../reducers/submitInsurance";
import { MotionButton } from "../motion/MotionButton";

export default (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(props.show);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isOpenResetPwdModal, setIsOpenResetPwdModal] = useState(false);

  useEffect(() => {
    // console.log(modalIsOpen);
  }, [modalIsOpen]);

  const changePasswordType = () => {
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  const changeConfirmPasswordType = () => {
    setConfirmPasswordType(
      confirmPasswordType == "password" ? "text" : "password"
    );
  };

  const validationSchema = Yup.object().shape({

    password1: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password1"), null], "Confirm Password does not match"),
  });

  const formik = useFormik({
    initialValues: {
      password1: "",
      confirmPassword: "",
    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      let body = {
        password: data?.password1,
        confirmPassword: data?.confirmPassword,
      };
      props.setSubmitResetPasswordData(body);
    },
  });

  const setResetPwd = (value) => {
    setIsOpenResetPwdModal(value);
  }

  return (
    <>
      <Modal show={props.show} onHide={()=>props.close()} keyboard={false} centered>
        {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <section className="forget_section">
            <div className="container">
              <form onSubmit={formik.handleSubmit} id="resetPasswordForm">
                <div className="forget_box">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <p>
                        Reset Password
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={passwordType}
                          name="password1"
                          className={
                            "form-control forget_input" +
                            (formik.errors.password1 && formik.touched.password1
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="New Password"
                          onChange={formik.handleChange}
                          value={formik.values.password1}
                        />
                        <div className="warn-feedback" style={{textAlign: "left"}}>
                          Password must contain at least 8 characters long with a mix of letters, numbers and symbols
                        </div>
                        <div className="invalid-feedback">
                          {formik.errors.password1 && formik.touched.password1
                            ? formik.errors.password1
                            : null}
                        </div>
                        <i
                          className="fa fa-eye"
                          onClick={() => changePasswordType()}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={confirmPasswordType}
                          name="confirmPassword"
                          className={
                            "form-control forget_input" +
                            (formik.errors.confirmPassword &&
                              formik.touched.confirmPassword
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Confirm Password"
                          onChange={formik.handleChange}
                          value={formik.values.confirmPassword}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.confirmPassword &&
                            formik.touched.confirmPassword
                            ? formik.errors.confirmPassword
                            : null}
                        </div>
                        <i
                          className="fa fa-eye"
                          onClick={() => changeConfirmPasswordType()}
                        ></i>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="submit"
                          className="btn_success "
                          form="resetPasswordForm" onClick={() => setResetPwd(true)}
                        >
                          Submit
                        </button>
                      </MotionButton>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="button"
                          className="btn_default "
                          onClick={()=>props.close()}
                        >
                          Cancel
                        </button>
                      </MotionButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};
