import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { saveInsurance } from "../../reducers/submitInsurance";
import "react-toastify/dist/ReactToastify.css";
import Header from "../partials/Header";
import { motion, AnimatePresence, color } from "framer-motion";
import { MotionButton } from "../motion/MotionButton";

import tabHealth from "../../assets/images/tab-health.svg";
import tabotp from "../../assets/images/tab-otp.svg";
import mobHealth from "../../assets/images/mob-health.svg";
import Group831 from "../../assets/images/Group831.png";
import { setShowPahrmacyMap } from "../../reducers/medication";
import { fetchInsuranceInfo } from "../../reducers/getInsuranceInfo";

import { genderOptions } from "./content";
import "./insurance.css";
import Form from "react-bootstrap/Form";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { successToast, errorToast } from "../toast/toast";

const Insurance = () => {
  const [insurance, setInsurance] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const pickerRef = useRef(null)
  const user = JSON.parse(localStorage.getItem("user"));
  const hasPharmacy = user?.hasPharmacy;


  useEffect(() => {
    user.firstName && formik.setFieldValue("firstName", user.firstName);
    user.lastName && formik.setFieldValue("lastName", user.lastName);
    user.middleName && formik.setFieldValue("lastName", user.middleName);
    user.gender && formik.setFieldValue("gender", user.gender);
    if (/^\d{8}$/.test(user.dob) && parseInt(user.dob)) {
      const year = parseInt(user.dob.substr(0, 4));
      const month = parseInt(user.dob.substr(4, 2)) - 1;
      const day = parseInt(user.dob.substr(6, 2));
      const convertedDate = new Date(year, month, day);
      formik.setFieldValue("dob", convertedDate)
    }
    dispatch(fetchInsuranceInfo({ appUserSeqNo: user?.appUserSeqNo })).unwrap().then((res) => {
      if (res.data && res.data.length) {
        let {
          memberId,
          groupNumber,
          pcn,
          bin,
          firstName,
          lastName,
          middleName,
          dob,
          gender,
        } = res.data[0]
        firstName && formik.setFieldValue("firstName", firstName)
        lastName && formik.setFieldValue("lastName", lastName)
        middleName && formik.setFieldValue("middleName", middleName)
        pcn && formik.setFieldValue("pcn", pcn)
        bin && formik.setFieldValue("bin", bin)
        groupNumber && formik.setFieldValue("groupNumber", groupNumber)
        gender && formik.setFieldValue("gender", gender)
        memberId && formik.setFieldValue("memberId", memberId)
        if (/^\d{8}$/.test(dob) && parseInt(dob)) {
          const year = parseInt(dob.substr(0, 4));
          const month = parseInt(dob.substr(4, 2)) - 1;
          const day = parseInt(dob.substr(6, 2));
          const convertedDate = new Date(year, month, day);
          formik.setFieldValue("dob", convertedDate)
        }
      }
    })
  }, [])

  useEffect(() => {
    pickerRef.current.input.readOnly = true;
  }, [pickerRef])

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    const yyyy = date.getFullYear();
    return `${mm}-${dd}-${yyyy}`;
  }

  function onChangeValue(event) {
    setInsurance(event.target.value);
    // console.log(event.target.value);
    if (event.target.value === "No") {
      navigate("/medications");
    }
  }

  const onSkip = () => {
    setInsurance("NO");
    // console.log("test");
    navigate("/medications");
  };

  const NumberVerify = () => {
    navigate("/otpVerify");
  };

  const CopayProgram = () => {
    formik.resetForm();
    if (hasPharmacy)
      navigate("/medications");
    else {
      dispatch(setShowPahrmacyMap(true))
      navigate("/medications")
    }
  };

  const medication = () => {
    navigate("/medications");
  };
  const Insurance = () => {
    navigate("/insurance");
  };

  const FindPharmacy = () => {
    navigate("/FindPharmacy");
  };

  const submit = () => {
    // navigate(`/medications`, { state: state });
  };

  const validationSchema = Yup.object().shape({
    bin: Yup.string().required("Required"),
    firstName: Yup.string().required("Required"),
    middleName: Yup.string(),
    lastName: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    dob: Yup.string().required("Required"),
    // username: Yup.string()
    //     .required('Username is required')
    //     .min(6, 'Username must be at least 6 characters')
    //     .max(20, 'Username must not exceed 20 characters'),
    // email: Yup.string().required('Email is required').email('Email is invalid'),
    pcn: Yup.string(),
    groupNumber: Yup.string().required("Required"),
    memberId: Yup.string().required("Required"),
    // confirmPassword: Yup.string()
    //     .required('Confirm Password is required')
    //     .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      dob: "",
      gender: "",
      bin: "",
      pcn: "",
      groupNumber: "",
      memberId: "",
    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      let body = {
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        dob: String(data?.dob.getFullYear()) + String(data?.dob.getMonth() + 1).padStart(2, '0') + String(data?.dob.getDate()).padStart(2, '0'),
        gender: data?.gender,
        bin: data?.bin,
        pcn: data?.pcn,
        groupNumber: data?.groupNumber,
        memberId: data?.memberId,
        appUserSeqNo: user?.appUserSeqNo,
      };
      dispatch(saveInsurance(body))
        .unwrap()
        .then((response) => {
          if (response?.data) {
            successToast("Insurance has been added successfully.");
            navigate("/medications");
          } else if (response?.message != "OK") {
            errorToast(response?.message);
          }
        })
        .catch((e) => {
        });
    },
  });

  return (
    <>
      <AnimatePresence mode="wait">
        <section className="fadeAni" >
          <div className="row g-0">
            <div className="col-sm-12 col-md-12 col-lg-12 ">
              <div className="">&nbsp;</div>
            </div>
          </div>
          <div  >
            <div className="row g-0" style={{marginTop: "3vw"}}>
              <div className="col-12 col-sm-12 col-md-12 col-lg-5 p-0 autoMargin">
                <div className="vector-image "  >
                  <motion.img
                    key={tabHealth}
                    // src={image.src}
                    initial={{ opacity: 0, x: 500 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0 }}
                    src={tabHealth}
                    transition={{ duration: 0.8, type: "tween" }}
                    className="img-fluid  Vector"
                  />
                  {/* </AnimatePresence> */}
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-12 col-lg-7 paddingZero autoMargin"
                style={{ zIndex: 100 }}
              >
                <div className="">

                  <motion.div
                    className="insurance_inner_main insurance-padding"
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <form onSubmit={formik.handleSubmit} id="loginID">
                      <div className="row" id="cc-info-div">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <motion.div
                            className="insurance_inner"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.7 }}
                          >
                            <div className="row webView">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                {
                                  !hasPharmacy && <div className="page_counter" style={{marginTop: -30, paddingBottom: 20}}>
                                    <h4 style={{fontSize: 14}}>1</h4>
                                    <motion.h3
                                      className="active"
                                      initial={{ scale: 0.5 }}
                                      animate={{ scale: 1 }}
                                      transition={{ duration: 1 }}
                                    >
                                      2
                                    </motion.h3>
                                    <h4 style={{fontSize: 14}}>3</h4>
                                  </div>
                                }
                              </div>
                            </div>
                            <motion.div
                              className="row"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.8 }}
                            >
                              <div className={`col-12 col-sm-12 col-md-12 col-lg-12 ${!hasPharmacy && "mt-4"}`}>
                                <h1>Do you have health insurance?</h1>
                              </div>
                            </motion.div>
                            <motion.div
                              className="row"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.8, delay: 0.15 }}
                            >
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <p>
                                  Although not required, adding your insurance
                                  guarantees we can present the best medication
                                  savings options for you
                                </p>
                                <p>
                                  Please use the same information as it appears
                                  on your insurance card:
                                </p>
                              </div>
                            </motion.div>
                            <motion.div
                              className="row g-1"
                              initial={{ x: 300, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.8, delay: 0.25 }}
                              exit={{ x: 300 }}
                            >
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group">
                                <input
                                  type="text"
                                  name="firstName"
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.firstName && formik.touched.firstName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="First Name*"
                                  onChange={formik.handleChange}
                                  value={formik.values.firstName}
                                />
                                <div className="invalid-feedback">
                                  {" "}
                                  {formik.errors.firstName && formik.touched.firstName
                                    ? formik.errors.firstName
                                    : null}{" "}
                                </div>
                              </div>
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group">
                                <input
                                  type="text"
                                  name="middleName"
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.middleName && formik.touched.middleName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Middle Name"
                                  onChange={formik.handleChange}
                                  value={formik.values.middleName}
                                />
                                <div className="invalid-feedback">
                                  {" "}
                                  {formik.errors.middleName && formik.touched.middleName
                                    ? formik.errors.middleName
                                    : null}
                                </div>
                              </div>
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group">
                                <input
                                  type="text"
                                  name="lastName"
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.lastName &&
                                      formik.touched.lastName
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Last Name*"
                                  onChange={formik.handleChange}
                                  value={formik.values.lastName}
                                />
                                <div className="invalid-feedback">
                                  {" "}
                                  {formik.errors.lastName &&
                                    formik.touched.lastName
                                    ? formik.errors.lastName
                                    : null}
                                </div>
                              </div>
                            </motion.div>
                            <motion.div
                              className="row g-1"
                              initial={{ x: 300, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.8, delay: 0.25 }}
                              exit={{ x: 300 }}
                            >
                              <div className="col-6 col-sm-3 col-md-4 col-lg-4 form-group">
                                <select
                                  name="gender"
                                  style={{ color: formik.values.gender === "" && "gray" }}
                                  defaultValue=""
                                  className={
                                    "form-select  custom-form-control custom-form-select" +
                                    (formik.errors.gender && formik.touched.gender
                                      ? " is-invalid form-select-is-invalid"
                                      : "")
                                  }
                                  onChange={formik.handleChange}
                                  value={formik.values.gender}
                                >
                                  <option value="" hidden disabled defaultValue="">Birth Sex*</option>
                                  {genderOptions.map(option => (
                                    <option key={option.value} value={option.value} style={{ color: "black" }}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                <div className="invalid-feedback">
                                  {" "}
                                  {formik.errors.gender && formik.touched.gender
                                    ? formik.errors.gender
                                    : null}{" "}
                                </div>
                              </div>
                              <div className="col-6 col-sm-4 col-md-4 col-lg-4 form-group">
                                <div className="date-picker-container">
                                  <DatePicker
                                    ref={pickerRef}
                                    name="dob"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    maxDate={new Date()}
                                    className={
                                      "form-control  custom-form-control" +
                                      (formik.errors.dob && formik.touched.dob
                                        ? " is-invalid"
                                        : "")
                                    }
                                    onChange={date => {
                                      formik.setFieldValue('dob', date)
                                    }}
                                    selected={formik.values.dob}
                                    placeholderText="Date of Birth*"
                                  />

                                </div>
                                <div className="date-picker-feedback invalid-feedback">
                                  {" "}
                                  {formik.errors.dob && formik.touched.dob
                                    ? formik.errors.dob
                                    : null}{" "}
                                </div>
                              </div>
                            </motion.div>
                            <motion.div
                              className="row g-1"
                              initial={{ x: 300, opacity: 0 }}
                              animate={{ x: 0, opacity: 1 }}
                              transition={{ duration: 0.8, delay: 0.25 }}
                              exit={{ x: 300 }}
                            >
                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group">
                                <input
                                  type="text"
                                  name="bin"
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.bin && formik.touched.bin
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="BIN*"
                                  onChange={formik.handleChange}
                                  value={formik.values.bin}
                                />
                                <div className="invalid-feedback">
                                  {" "}
                                  {formik.errors.bin && formik.touched.bin
                                    ? formik.errors.bin
                                    : null}{" "}
                                </div>
                              </div>
                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group">
                                <input
                                  type="text"
                                  name="pcn"
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.pcn && formik.touched.pcn
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="PCN"
                                  onChange={formik.handleChange}
                                  value={formik.values.pcn}
                                />
                                <div className="invalid-feedback">
                                  {" "}
                                  {formik.errors.pcn && formik.touched.pcn
                                    ? formik.errors.pcn
                                    : null}
                                </div>
                              </div>
                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group">
                                <input
                                  type="text"
                                  name="groupNumber"
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.groupNumber &&
                                      formik.touched.groupNumber
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Group*"
                                  onChange={formik.handleChange}
                                  value={formik.values.groupNumber}
                                />
                                <div className="invalid-feedback">
                                  {" "}
                                  {formik.errors.groupNumber &&
                                    formik.touched.groupNumber
                                    ? formik.errors.groupNumber
                                    : null}
                                </div>
                              </div>
                              <div className="col-6 col-sm-3 col-md-3 col-lg-3 form-group">
                                <input
                                  type="text"
                                  name="memberId"
                                  className={
                                    "form-control  custom-form-control" +
                                    (formik.errors.memberId &&
                                      formik.touched.memberId
                                      ? " is-invalid"
                                      : "")
                                  }
                                  placeholder="Member ID*"
                                  onChange={formik.handleChange}
                                  value={formik.values.memberId}
                                />
                                <div className="invalid-feedback">
                                  {" "}
                                  {formik.errors.memberId &&
                                    formik.touched.memberId
                                    ? formik.errors.memberId
                                    : null}
                                </div>
                              </div>
                            </motion.div>
                            <motion.div
                              className="button_div insurance_inner_main_btn mt-4 g-0"
                              id="cc-info-div-smbt"
                              initial={{ x: 300 }}
                              animate={{ x: 0 }}
                              transition={{ duration: 0.7 }}
                            >
                              <div className="margin-right">
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    type="submit"
                                    onClick={() => submit()}
                                  >
                                    Submit
                                  </button>
                                </MotionButton>
                              </div>

                              <div className="margin-right webView">
                                <MotionButton>
                                  <button
                                    className="btn_default NIbutton "
                                    type="button"
                                    onClick={() => CopayProgram()}
                                  >
                                    No&nbsp;insurance
                                  </button>
                                </MotionButton>
                              </div>
                              <div className="margin-right webView">
                                <MotionButton>
                                  <button
                                    type="button"
                                    className="btn_default NIbutton"
                                    onClick={() => CopayProgram()}
                                  >
                                    Skip
                                  </button>
                                </MotionButton>
                              </div>
                              {/* for <obile View */}
                              <div className="row mobileView">
                                <div className="col-6">
                                  <MotionButton>
                                    <button
                                      className="btn_default "
                                      type="button"
                                      onClick={() => CopayProgram()}
                                    >
                                      No&nbsp;insurance
                                    </button>
                                  </MotionButton>
                                </div>
                                <div className="col-6">
                                  <MotionButton>
                                    <button
                                      type="button"
                                      className="btn_default "
                                      onClick={() => CopayProgram()}
                                    >
                                      Skip
                                    </button>
                                  </MotionButton>
                                </div>
                              </div>
                            </motion.div>
                          </motion.div>
                          <motion.div
                            className="button_div insurance_inner_main_btn2  g-0"
                            id="cc-info-div-smbt"
                            initial={{ x: 300 }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.7 }}
                          >
                            <div className="margin-right">
                              <MotionButton>
                                <button
                                  className="btn_success"
                                  type="submit"
                                  onClick={() => submit()}
                                >
                                  Submit
                                </button>
                              </MotionButton>
                            </div>

                            <div className="margin-right webView">
                              <MotionButton>
                                <button
                                  className="btn_default NIbutton "
                                  type="button"
                                  onClick={() => CopayProgram()}
                                >
                                  No&nbsp;insurance
                                </button>
                              </MotionButton>
                            </div>
                            <div className="margin-right webView">
                              <MotionButton>
                                <button
                                  className="btn_default NIbutton"
                                  type="button"
                                  onClick={() => CopayProgram()}
                                >
                                  Skip
                                </button>
                              </MotionButton>
                            </div>
                            {/* for mobile View */}
                            <div className="row mobileView">
                              <div className="col-6">
                                <MotionButton>
                                  <button
                                    className="btn_default "
                                    type="button"
                                    onClick={() => CopayProgram()}
                                  >
                                    No&nbsp;insurance
                                  </button>
                                </MotionButton>
                              </div>
                              <div className="col-6">
                                <MotionButton>
                                  <button
                                    className="btn_default "
                                    type="button"
                                    onClick={() => CopayProgram()}
                                  >
                                    Skip
                                  </button>
                                </MotionButton>
                              </div>
                            </div>
                          </motion.div>
                        </div>
                      </div>
                    </form>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobileView">
            <div className="row g-0">
              <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
                <div className="dot-main">
                  <span className="dot"></span>
                  <span className="dot dotActive"></span>
                  <span className="dot"></span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </AnimatePresence>
    </>
  );
};


export default Insurance;