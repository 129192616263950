import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { saveInsurance } from "../../reducers/submitInsurance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import smallImage from "../../assets/images/smallimg.png";
import { MotionButton, MotionIcon } from "../motion/MotionButton";
import MapContainer from '../map/MapContainer';

export default () => {
  const [insurance, setInsurance] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  var userDetail = JSON.parse(localStorage.getItem("user"));

  const NumberVerify = () => {
    navigate("/otpVerify");
  };

  const CopayProgram = () => {
    navigate("/CopayProgram");
  };

  const Insurance = () => {
    navigate("/insurance");
  };

  const FindPharmacy = () => {
    navigate("/FindPharmacy");
  };

  const medication = () => {
    navigate("/medications");
  };

  const validationSchema = Yup.object().shape({
  });

  const formik = useFormik({
    initialValues: {
      bin: "",
      pcn: "",
      groupNumber: "",
      memberId: "",
    },
    validationSchema,
    onSubmit: (data) => { },
  });


  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg12">
          <div className="">
            <MapContainer />
          </div>
        </div>
      </div>
      <section className="findPharmacy_section" >
        <div className="fadeAni findPharmacy">
          <div className="row ">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div
                className="medicine_icon pharma_icon"
                onClick={() => CopayProgram()}
              >
                <MotionIcon><i className="fas fa-angle-left"></i></MotionIcon>
              </div>
            </div>
          </div>
          <div className="row margintop10">
            {/* <div className="col-sm-12 col-md-12 col-lg-6"></div> */}
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className=" ">
                <div className="insurance_inner_main">
                  <form onSubmit={formik.handleSubmit} id="loginID">
                    <div className="row" id="cc-info-div">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="pharma_home_one mt-0">
                          <div className="insurance_inner ">
                            <div className="row webView">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="page_counter">
                                  <p onClick={() => NumberVerify()}>1</p>
                                  <p onClick={() => Insurance()}>2</p>
                                  <p onClick={() => CopayProgram()}>3</p>
                                  <p
                                    onClick={() => FindPharmacy()}
                                    className="active"
                                  >
                                    4
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <h1>
                                  <b>Find your pharmacy</b>
                                </h1>
                                <h6>
                                  <b>
                                    Please select the pharmacy where your
                                    medications have been prescribed to
                                  </b>
                                </h6>
                              </div>
                            </div>
                            <div className="row" id="cc-info-div-smbt">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="input-div input_pharma">
                                  <i>
                                    <FontAwesomeIcon icon={faSearch} />
                                  </i>
                                  <input
                                    placeholder="Lookup pharmacy name or address"
                                    className="search-bar pharma-search-bar input_pharma"
                                  />
                                  {/* {optionss.length > 0 && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="searchBar-icon mx-2">
                    {optionss.map((searchResult) => (
                      <li
                        tabIndex={0}
                        className="list-style"
                        onClick={() => drugDetails(searchResult.id)}
                      >
                        {searchResult.name}
                      </li>
                    ))}
                  </div>
                </div>
              )} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-5 tabView" ></div>
                    <div className="row mt-5 tabView"></div>
                    <div className="row mt-5 tabView"></div>

                  </form>
                </div>
                <div className="insurance_inner_main insurance_inner_main_pad">
                  <form onSubmit={formik.handleSubmit} id="loginID">

                    <div className="row ">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-0">
                        <div className="pharma-inner-bg">
                          <div className="pharma_inner">
                            <div className="row">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                <img src={smallImage} className="img-fluid " />
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="">
                                  <p>Shop Rite</p>
                                  <span>3125 Bainbridge Ave</span>
                                </div>
                              </div>
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 pl-0">
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    type="submit" onClick={() => medication()}
                                  >
                                    Select
                                  </button>
                                </MotionButton>
                              </div>
                            </div>
                          </div>
                          <div className="pharma_inner ">
                            <div className="row">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                <img src={smallImage} className="img-fluid " />
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="">
                                  <p>Shop Rite</p>
                                  <span>3125 Bainbridge Ave</span>
                                </div>
                              </div>
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 pl-0">
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    type="submit" onClick={() => medication()}
                                  >
                                    Select
                                  </button>
                                </MotionButton>
                              </div>
                            </div>
                          </div>
                          <div className="pharma_inner">
                            <div className="row">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                <img src={smallImage} className="img-fluid " />
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="">
                                  <p>Shop Rite</p>
                                  <span>3125 Bainbridge Ave</span>
                                </div>
                              </div>
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 pl-0">
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    type="submit" onClick={() => medication()}
                                  >
                                    Select
                                  </button>
                                </MotionButton>
                              </div>
                            </div>
                          </div>
                          <div className="pharma_inner mobileView">
                            <div className="row">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                <img src={smallImage} className="img-fluid " />
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="">
                                  <p>Shop Rite</p>
                                  <span>3125 Bainbridge Ave</span>
                                </div>
                              </div>
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 pl-0">
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    type="submit" onClick={() => medication()}
                                  >
                                    Select
                                  </button>
                                </MotionButton>
                              </div>
                            </div>
                          </div>
                          <div className="pharma_inner mobileView">
                            <div className="row">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                <img src={smallImage} className="img-fluid " />
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="">
                                  <p>Shop Rite</p>
                                  <span>3125 Bainbridge Ave</span>
                                </div>
                              </div>
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 pl-0">
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    type="submit" onClick={() => medication()}
                                  >
                                    Select
                                  </button>
                                </MotionButton>
                              </div>
                            </div>
                          </div>
                          <div className="pharma_inner mobileView">
                            <div className="row">
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                <img src={smallImage} className="img-fluid " />
                              </div>
                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="">
                                  <p>Shop Rite</p>
                                  <span>3125 Bainbridge Ave</span>
                                </div>
                              </div>
                              <div className="col-3 col-sm-3 col-md-3 col-lg-3 pl-0">
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    type="submit" onClick={() => medication()}
                                  >
                                    Select
                                  </button>
                                </MotionButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row mobileView">
            <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
              <div className="dot-main">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot dotActive"></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
