import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import avatar from "../../assets/images/RxLink assets/avatar icon.svg";
import editIcon from "../../assets/images/RxLink assets/edit icon.svg";
import closeIcon from "../../assets/images/RxLink assets/close icon.svg";
import { useNavigate } from 'react-router-dom';
import { MotionTextH2 } from "../motion/MotionText";
import { MotionIcon } from '../motion/MotionButton';
import { MotionButton } from '../motion/MotionButton';
import { useFormik } from 'formik';
import './myAccount.css';
import * as Yup from "yup";
import { fetchInsuranceInfo } from '../../reducers/getInsuranceInfo';
import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { saveProfile } from '../../reducers/submitProfile';
import InputMask from "react-input-mask";
import { genderOptions } from '../insurance/content';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { successToast, errorToast } from '../toast/toast';


const MyAccount = () => {
    const dispatch = useDispatch();
    const [initialData, setInitialData] = useState(null)
    const [editProfileEnabled, setEditProfileEnabled] = useState(false)
    const [editInsuranceEnabled, setEditInsuranceEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const pickerRef = useRef(null)
    var userDetail = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate();

    const resetFields = () => {
        setEditInsuranceEnabled(false)
        setEditProfileEnabled(false)
    }

    useEffect(() => {
        if (pickerRef.current !== null)
            pickerRef.current.input.readOnly = true;
    }, [pickerRef])

    useEffect(() => {
        if (!editProfileEnabled && initialData) {
            initialData.firstName && formik.setFieldValue("firstName", initialData.firstName)
            initialData.middleName && formik.setFieldValue("middleName", initialData.middleName)
            initialData.lastName && formik.setFieldValue("lastName", initialData.lastName)
            initialData.gender && formik.setFieldValue("gender", initialData.gender)
            initialData.email && formik.setFieldValue("email", initialData.email)
            initialData.address && formik.setFieldValue("address", initialData.address)
            userDetail.username && formik.setFieldValue("username", userDetail.username)
            if (/^\d{8}$/.test(initialData.dob) && parseInt(initialData.dob)) {
                const year = parseInt(initialData.dob.substr(0, 4));
                const month = parseInt(initialData.dob.substr(4, 2)) - 1;
                const day = parseInt(initialData.dob.substr(6, 2));
                const convertedDate = new Date(year, month, day);
                formik.setFieldValue("dob", convertedDate)
            }

        }
        if (!editInsuranceEnabled && initialData) {
            initialData.pcn && formik.setFieldValue("pcn", initialData.pcn)
            initialData.bin && formik.setFieldValue("bin", initialData.bin)
            initialData.groupNumber && formik.setFieldValue("groupNumber", initialData.groupNumber)
            initialData.memberId && formik.setFieldValue("memberId", initialData.memberId)
        }

    }, [editProfileEnabled, editInsuranceEnabled])

    const checkNullOrUndefined = (arg) => {
        if (arg === undefined || arg === null) {
            return false;
        }
        else {
            return true;
        }
    }
    useEffect(() => {

        if (!loading) {
            dispatch(fetchInsuranceInfo({ appUserSeqNo: userDetail?.appUserSeqNo })).unwrap().then((res) => {
                if (res.data && res.data.length) {
                    let {
                        memberId,
                        groupNumber,
                        pcn,
                        bin,
                        firstName,
                        middleName,
                        lastName,
                        insInfoSeqNo,
                        patientSeqNo,
                        hasAlerts,
                        email,
                        address,
                        dob,
                        gender,
                    } = res.data[0]
                    res.data[0] && setInitialData(res.data[0])
                    checkNullOrUndefined(firstName) && formik.setFieldValue("firstName", firstName)
                    checkNullOrUndefined(middleName) && formik.setFieldValue("middleName", middleName)
                    lastName && formik.setFieldValue("lastName", lastName)
                    pcn && formik.setFieldValue("pcn", pcn)
                    bin && formik.setFieldValue("bin", bin)
                    groupNumber && formik.setFieldValue("groupNumber", groupNumber)
                    memberId && formik.setFieldValue("memberId", memberId)
                    hasAlerts && formik.setFieldValue("hasAlerts", hasAlerts)
                    email && formik.setFieldValue("email", email)
                    address && formik.setFieldValue("address", address)
                    gender && formik.setFieldValue("gender", gender)
                    userDetail.username && formik.setFieldValue("username", userDetail.username)
                    insInfoSeqNo && formik.setFieldValue("insInfoSeqNo", insInfoSeqNo)
                    patientSeqNo && formik.setFieldValue("patientSeqNo", patientSeqNo)
                    if (/^\d{8}$/.test(dob) && parseInt(dob)) {
                        const year = parseInt(dob.substr(0, 4));
                        const month = parseInt(dob.substr(4, 2)) - 1;
                        const day = parseInt(dob.substr(6, 2));
                        const convertedDate = new Date(year, month, day);
                        formik.setFieldValue("dob", convertedDate)
                    }
                }
                else {
                    userDetail.username && formik.setFieldValue("username", userDetail.username)
                }
            })
        }
    }, [loading])


    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("Required"),
        lastName: Yup.string().required("Required"),
        gender: Yup.string().required("Required"),
        dob: Yup.string().required("Required"),
        // email: Yup.string().required('Email is required').email('Email is invalid'),
        // username: Yup.string().required("Phone number is required"),
        bin: Yup.string().required("Required"),
        pcn: Yup.string(),
        groupNumber: Yup.string().required("Required"),
        memberId: Yup.string().required("Required"),
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            middleName: "",
            lastName: "",
            gender: "",
            dob: "",
            email: "",
            username: "",
            address: "",
            bin: "",
            pcn: "",
            groupNumber: "",
            memberId: "",
            insInfoSeqNo: "",
            patientSeqNo: "",
            hasAlerts: false,
        },
        validationSchema,
        onSubmit: (data) => {
            setLoading(true)
            let body = {
                insInfoSeqNo: data?.insInfoSeqNo,
                appUserSeqNo: userDetail?.appUserSeqNo,
                patientSeqNo: userDetail?.patientSeqNo,
                firstName: data?.firstName,
                middleName: data?.middleName,
                lastName: data?.lastName,
                dob: String(data?.dob.getFullYear()) + String(data?.dob.getMonth() + 1).padStart(2, '0') + String(data?.dob.getDate()).padStart(2, '0'),
                gender: data?.gender,
                address: data?.address,
                memberId: data?.memberId,
                groupNumber: data?.groupNumber,
                bin: data?.bin,
                pcn: data?.pcn,
                hasAlerts: data?.hasAlerts,
            }
            dispatch(saveProfile(body))
                .unwrap()
                .then((response) => {
                    setLoading(false)
                    resetFields()
                    // console.log({ response })
                    if (response?.statusCode === 200 & response?.status === "OK") {
                        successToast("Profile has been updated successfully.");
                    } else if (response?.status !== "OK") {
                        errorToast(response?.message);
                    }
                })
                .catch((e) => {
                    setLoading(false)
                });
        },
    });

    return (
        <>
            {!loading ?
                <section className="mt-3 mb-5">
                    <form onSubmit={formik.handleSubmit} id="loginID">
                        <div className="container account">
                            <div className="row">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="medicine_icon pharma_icon" onClick={() => window.history.back()}>
                                        <MotionIcon>
                                            <i className="fas fa-angle-left"></i>
                                        </MotionIcon>
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="medicine_icon medicine_icon_m">
                                        <div className="">
                                            <motion.img
                                                whileHover={{ scale: 1.1 }}
                                                src={avatar}
                                                className="img-fluid pointer"
                                                id="dropdownMenuButton1"
                                                aria-expanded="false"
                                                type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="medication-heading">
                                        <MotionTextH2 text="My Account" duration={0.03} delay={0.15} />
                                    </div>
                                </div>
                            </div>
                            <div className='sub_div'>
                                <span className="sub_heading">PERSONAL INFORMATION</span>
                                {
                                    !editProfileEnabled ?
                                        <motion.img
                                            whileHover={{ scale: 1.1 }}
                                            src={editIcon}
                                            className="edit_icon img-fluid pointer"
                                            onClick={() => setEditProfileEnabled(prev => !prev)}
                                        />
                                        :
                                        <motion.img
                                            whileHover={{ scale: 1.1 }}
                                            src={closeIcon}
                                            className="close_icon img-fluid pointer"
                                            onClick={() => setEditProfileEnabled(prev => !prev)}
                                        />
                                }
                            </div>
                            <div className='card_view mt-3 mb-3 col-sm-12 col-md-12 col-lg-12'>
                                <motion.div
                                    className="row"
                                    initial={{ x: 300, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.8, delay: 0.25 }}
                                    exit={{ x: 300 }}
                                >
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='firstName'
                                            className={`form-control  custom-form-control ${!editProfileEnabled && "disabled-input"}` +
                                                (formik.errors.firstName && formik.touched.firstName
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='First Name'
                                            disabled={!editProfileEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.firstName}
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='middleName'
                                            className={`form-control  custom-form-control ${!editProfileEnabled && "disabled-input"}` +
                                                (formik.errors.middleName && formik.touched.middleName
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Middle Name'
                                            disabled={!editProfileEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.middleName}
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='lastName'
                                            className={`form-control  custom-form-control ${!editProfileEnabled && "disabled-input"}` +
                                                (formik.errors.lastName && formik.touched.lastName
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Last Name'
                                            disabled={!editProfileEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.lastName}
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='email'
                                            className={`form-control  custom-form-control ${true && "disabled-input"}` +
                                                (formik.errors.email && formik.touched.email
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Email'
                                            disabled={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <InputMask
                                            name='username'
                                            className={`form-control  custom-form-control ${true && "disabled-input"}` +
                                                (formik.errors.username && formik.touched.username
                                                    ? " is-invalid"
                                                    : "")}
                                            id="floatingInputPhone"
                                            mask="(999) 999-9999"
                                            maskChar={null}
                                            placeholder='Phone Number'
                                            disabled={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.username}
                                        >
                                        </InputMask>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='address'
                                            className={`form-control  custom-form-control ${!editProfileEnabled && "disabled-input"}` +
                                                (formik.errors.address && formik.touched.address
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Address'
                                            disabled={!editProfileEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.address}
                                        >
                                        </input>
                                    </div>
                                </motion.div>
                            </div>
                            <div className='divider mt-3 mb-3' />
                            <div className='sub_div'>
                                <span className="sub_heading">INSURANCE</span>
                                {
                                    !editInsuranceEnabled ?
                                        <motion.img
                                            whileHover={{ scale: 1.1 }}
                                            src={editIcon}
                                            className="edit_icon img-fluid pointer"
                                            onClick={() => setEditInsuranceEnabled(prev => !prev)}
                                        />
                                        :
                                        <motion.img
                                            whileHover={{ scale: 1.1 }}
                                            src={closeIcon}
                                            className="close_icon img-fluid pointer"
                                            onClick={() => setEditInsuranceEnabled(prev => !prev)}
                                        />
                                }
                            </div>
                            <div className='card_view mt-3 mb-3 col-sm-12 col-md-12 col-lg-12'>
                                <motion.div
                                    className="row"
                                    initial={{ x: 300, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.8, delay: 0.25 }}
                                    exit={{ x: 300 }}
                                >
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='bin'
                                            className={`form-control  custom-form-control ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.bin && formik.touched.bin
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='BIN'
                                            disabled={!editInsuranceEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.bin}
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='pcn'
                                            className={`form-control  custom-form-control ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.pcn && formik.touched.pcn
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='PCN'
                                            disabled={!editInsuranceEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.pcn}
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='groupNumber'
                                            className={`form-control  custom-form-control ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.groupNumber && formik.touched.groupNumber
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Group'
                                            disabled={!editInsuranceEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.groupNumber}
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='memberId'
                                            className={`form-control  custom-form-control ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.memberId && formik.touched.memberId
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Member ID'
                                            disabled={!editInsuranceEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.memberId}
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <select
                                            name="gender"
                                            style={{ color: formik.values.gender === "" && "gray" }}
                                            defaultValue=""
                                            className={
                                                `form-select  custom-form-control custom-form-select ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.gender && formik.touched.gender
                                                    ? " is-invalid form-select-is-invalid"
                                                    : "")
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.gender}
                                            disabled={!editInsuranceEnabled}
                                        >
                                            <option value="" hidden disabled defaultValue="">Birth Sex*</option>
                                            {genderOptions.map(option => (
                                                <option key={option.value} value={option.value} style={{ color: "black" }}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <div className="date-picker-container">
                                            <DatePicker
                                                ref={pickerRef}
                                                name="dob"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                maxDate={new Date()}
                                                className={
                                                    `form-control  custom-form-control ${!editInsuranceEnabled && "disabled-input"}` +
                                                    (formik.errors.dob && formik.touched.dob
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                onChange={date => {
                                                    formik.setFieldValue('dob', date)
                                                }}
                                                selected={formik.values.dob}
                                                placeholderText="Date of Birth*"
                                                disabled={!editInsuranceEnabled}
                                            />

                                        </div>
                                    </div>
                                </motion.div>
                            </div>
                            {/* TOBE added later
                            <div className='divider mt-3 mb-3' />
                            <div className='sub_div'>
                                <span className="sub_heading">ALERTS</span>
                            </div>
                            <div className="row alerts mt-2 mb-2">
                                <div className="check-box-alert">
                                    <MotionIcon>
                                        <input type="checkbox" name='hasAlerts' className="p-1" onChange={(e) => { formik.setFieldValue('hasAlerts', e.target.checked) }} checked={formik.values.hasAlerts} />
                                    </MotionIcon>
                                </div>
                                <div className="text-box-alert col-10 col-sm-10 col-md-10 col-lg-10 mt-1 pl-0">
                                    <span>
                                        Receive alerts related to personalized medication savings and refill reminders
                                    </span>
                                </div>
                            </div> */}
                            <div className="col-12 col-sm-1 col-md-1 col-lg-1 mt-3">
                                <MotionButton>
                                    <button
                                        type="submit"
                                        className="btn_success"
                                    // disabled={!editProfileEnabled && !editInsuranceEnabled}
                                    >
                                        Save
                                    </button>
                                </MotionButton>
                            </div>
                        </div>
                    </form>
                </section>
                :
                <div className="loader"></div>
            }
        </>
    )
}

export default MyAccount
