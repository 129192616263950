

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { saveInsurance } from "../../reducers/submitInsurance";
import { MotionButton } from "../motion/MotionButton";

export default (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(props.show);
  const [tempPasswordType, setTempPasswordType] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const changeTempType = () => {
    setTempPasswordType(tempPasswordType == "password" ? "text" : "password");
  };
  const changePasswordType = () => {
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  const changeConfirmPasswordType = () => {
    setConfirmPasswordType(
      confirmPasswordType == "password" ? "text" : "password"
    );
  };

  const validationSchema = Yup.object().shape({
    tempPassword: Yup.string().required("Temporary password is required"),

    password1: Yup.string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters")
      .max(40, "Password must not exceed 40 characters")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()\-_=+{};:,<.>?[\]`~|'"\\/]{8,}$/,
        'Password must be 8 characters long and contain a mix of letters and numbers.'
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password1"), null], "New password does not match"),
  });

  const formik = useFormik({
    initialValues: {
      tempPassword: "",
      password1: "",
      confirmPassword: "",
    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      let body = {
        tempPassword: data?.tempPassword,
        password: data?.password1,
        confirmPassword: data?.confirmPassword,
      };
      props.setSubmitResetPasswordData(body);
    },
  });

  return (
    <>
      <Modal show={props.show} onHide={props.close} keyboard={false} centered>
        {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <section className="forget_section">
            <div className="container">
              <form onSubmit={formik.handleSubmit} id="resetPasswordForm">
                <div className="forget_box">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <p>
                        Reset Password
                      </p>
                      <div className="login_box">
                        <p>{props.isOldPassword ? "Please type your old password and choose a new strong password" : "Please type the temporary password we texted you and choose a new strong password"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={tempPasswordType}
                          name="tempPassword"
                          className={
                            "form-control forget_input" +
                            (formik.errors.tempPassword && formik.touched.tempPassword
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={props.isOldPassword ? "Old Password" : "Temporary Password"}
                          onChange={formik.handleChange}
                          value={formik.values.tempPassword}
                          inputmode={props.isOldPassword ? "" :"numeric"}
                          autocomplete="one-time-code"
                        />
                        <div className="invalid-feedback">
                          {formik.errors.tempPassword && formik.touched.tempPassword
                            ? formik.errors.tempPassword
                            : null}
                        </div>
                        <i
                          className= {tempPasswordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changeTempType()}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={passwordType}
                          name="password1"
                          className={
                            "form-control forget_input" +
                            (formik.errors.password1 && formik.touched.password1
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="New Password"
                          onChange={formik.handleChange}
                          value={formik.values.password1}
                        />
                        <div className="warn-feedback" style={{color: formik.errors.password1 && formik.touched.password1 ? "red" : "", textAlign: "left"}}>
                        Password must be 8 characters long and contain a mix of letters and numbers
                        </div>
                        {/* <div className="warn-feedback" >
                          of letters, numbers and symbols
                        </div> */}
                        {/* <div className="invalid-feedback">
                          {formik.errors.password1 && formik.touched.password1
                            ? formik.errors.password1
                            : null}
                        </div> */}
                        <i
                          className= {passwordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changePasswordType()}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={confirmPasswordType}
                          name="confirmPassword"
                          className={
                            "form-control forget_input" +
                            (formik.errors.confirmPassword &&
                              formik.touched.confirmPassword
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Confirm Password"
                          onChange={formik.handleChange}
                          value={formik.values.confirmPassword}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.confirmPassword &&
                            formik.touched.confirmPassword
                            ? formik.errors.confirmPassword
                            : null}
                        </div>
                        <i
                          className= {confirmPasswordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changeConfirmPasswordType()}
                        ></i>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="submit"
                          className="btn_success "
                          form="resetPasswordForm"
                        >
                          Submit
                        </button>
                      </MotionButton>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="button"
                          className="btn_default "
                          onClick={props.close}
                        >
                          Cancel
                        </button>
                      </MotionButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};
