import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png"; // for Rxlink
import upennLogo from "../../assets/images/PennMedicine.png"; // for PennMedicine
import papajohnsLogo from "../../assets/images/papajones-logo.png"; // for papaJohns
import tripmentLogo from "../../assets/images/tripment.png"; // for tripment
import houstonLogo from "../../assets/images/houston-health-logo.png"; // for houston-health
import lcmchealthlogo from "../../assets/images/LCMC_Health_Logo.png"; // for lcmc

import faceId from "../../assets/images/Face_ID_logo.png";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../reducers/auth";
import { userSignUp } from "../../reducers/signUp";
import { PID, domainName } from "../../environment";
import 'react-toastify/dist/ReactToastify.css';
import { motion, useAnimation } from "framer-motion";
import { routeVariants } from "../animationsVariants";
import { useInView } from "react-intersection-observer";
import { MotionText } from "../motion/MotionText";
import { errorToast } from "../toast/toast";
import { UsernameField, usernameValidation } from "../Fields/Username";


const SignUp = ({ auth, setAuth }) => {
  const [mask, setMask] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  var numberPattern = /\d+/g;
  const validationSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    username: usernameValidation,
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(40, "Password must not exceed 40 characters")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+._=]).{8,}$/,
        'Password needs mix letters, numbers and symbols.'
      ),
    verifyPassword: Yup.string()
      .required("Verify password is required")
      .oneOf([Yup.ref('password')], 'Passwords do not match')
  });

  const queryParameters = new URLSearchParams(window.location.search);
  const isUpenn = window.location.href.includes("upenn");
  const isPapaJohn = window.location.href.includes("papajohns") || window.location.href.includes("pju");
  const isTripment = window.location.href.includes("tripment");
  const isHouston = window.location.href.includes("hhc");
  const isLcmc = window.location.href.includes("lcmc");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      verifyPassword: "",
    },
    validationSchema,

    onSubmit: (data) => {
      setLoading(true);
      let body = {
        firstName: data?.firstName,
        lastName: data?.lastName,
        username: mask ? data.username.match(numberPattern).join("") : data.username,
        password: data?.password,
        pid: isUpenn ? "UPENN" : isPapaJohn ? "PAPAJOHNS" : isHouston ? "HHC" : isTripment ? "TRIPMENT" : isLcmc ? "LCMC" : "RXLINK",
        domainName: isUpenn ? "Upenn" : isPapaJohn ? "PapaJohns" : isTripment ? "Tripment" : isHouston ? "HHC" : isLcmc ? "LCMC" : "RxLink",
      };
      // localStorage.setItem("userBody", JSON.stringify(body));
      dispatch(userSignUp(body))
        .unwrap()
        .then((response) => {
          body.password = null;
          localStorage.setItem("userBody", JSON.stringify(body));

          setLoading(false);
          if (response?.statusCode >= 200 && response?.statusCode <= 299) {
            navigate("/otpVerify");
            // addToast(response?.message, { appearance: 'success', autoDismiss: 5000 });
          } else {
            // addToast(response?.message, {
            //   appearance: "error",
            //   autoDismiss: 5000,
            // });

            errorToast(response?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    },
  });

  const signIn = (e) => {
    navigate("/");
  };

  //Animations 
  const ctrls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  return (
    <>
      <section className="">
        <div className="login_section">
          <motion.form onSubmit={formik.handleSubmit} id="signUpID"
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="row g-0">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="login_section_inner">
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="logo">
                        <motion.img src={isUpenn ? upennLogo : isPapaJohn ? papajohnsLogo : isHouston ? houstonLogo : isTripment ? tripmentLogo : isLcmc ? lcmchealthlogo : logo} className="img-fluid"
                          initial={{ y: -100 }}
                          animate={{ y: 0 }}
                          transition={{ duration: 0.5, delay: 0.4 }}
                        />
                      </div>
                      {/* <h3>Personalized medication savings</h3> */}
                      <MotionText text={'Personalized medication savings'}
                        ctrls={ctrls}
                        wordAnimation={wordAnimation}
                        characterAnimation={characterAnimation}
                        cref={ref}
                      />
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-1 form-group">
                        <input
                          name="firstName"
                          type="text"
                          className={
                            "form-control custom-form-control login_input" +
                            (formik.errors.firstName && formik.touched.firstName
                              ? " is-invalid"
                              : "")
                          }
                          id="floatingInput"
                          placeholder="Enter your first name"
                          onChange={formik.handleChange}
                          value={formik.values.firstName}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.firstName && formik.touched.firstName
                            ? formik.errors.firstName
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-1  form-group">
                        <input
                          name="lastName"
                          type="text"
                          className={
                            "form-control custom-form-control login_input" +
                            (formik.errors.lastName && formik.touched.lastName
                              ? " is-invalid"
                              : "")
                          }
                          id="floatingInput"
                          placeholder="Enter your last name"
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.lastName && formik.touched.lastName
                            ? formik.errors.lastName
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <UsernameField
                    formik={formik}
                    mask={mask}
                    setMask={setMask} />
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-1  form-group">
                        <input
                          name="password"
                          type="password"
                          className={
                            "form-control custom-form-control login_input" +
                            (formik.errors.password && formik.touched.password
                              ? " is-invalid"
                              : "")
                          }
                          id="floatingInput"
                          placeholder="Enter your password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                        />
                        <div className="warn-feedback">
                          Password must contain at least 8 characters long with a mix
                        </div>
                        <div className="warn-feedback">
                          of letters, numbers and symbols
                        </div>
                        <div className="invalid-feedback">
                          {formik.errors.password && formik.touched.password
                            ? formik.errors.password
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="mb-1  form-group">
                        <input
                          name="verifyPassword"
                          type="password"
                          className={
                            "form-control custom-form-control login_input" +
                            (formik.errors.verifyPassword && formik.touched.verifyPassword
                              ? " is-invalid"
                              : "")
                          }
                          id="floatingInput"
                          placeholder="Verify your password"
                          onChange={formik.handleChange}
                          value={formik.values.verifyPassword}
                        />
                        <div className="invalid-feedback">
                          {formik.errors.verifyPassword && formik.touched.verifyPassword
                            ? formik.errors.verifyPassword
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <p className="password pointer">
                        <a>
                          Already have an account ?{" "}
                          <span
                            className="span_link topSignUp"
                            onClick={() => signIn()}
                          >
                            <u>Sign In</u>
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="row g-0">
                    <motion.div className="col-sm-12 col-md-12 col-lg-12"
                      initial={{ y: 50 }}
                      transition={{ type: "spring", duration: 0.8 }}
                      animate={{ y: 0 }}
                      whileTap={{
                        scale: 0.95,
                        transition: { duration: 0.1 },
                      }}
                    >
                      <button
                        className="btn_success"
                        type="submit"
                        form="signUpID"
                      >
                        Sign Up
                      </button>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </motion.form>
        </div>
      </section>
    </>
  );
};


export default SignUp;