import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { saveInsurance } from "../../reducers/submitInsurance";
import { successToast, errorToast } from "../toast/toast";
import 'react-toastify/dist/ReactToastify.css';
import Header from "../partials/Header";

import Vector from "../../assets/images/vactor.svg";
import Group831 from "../../assets/images/Group831.png";
import { MotionButton, MotionIcon } from "../motion/MotionButton";

export default () => {
  const [insurance, setInsurance] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  var userDetail = JSON.parse(localStorage.getItem("user"));

  function onChangeValue(event) {
    setInsurance(event.target.value);
    // console.log(event.target.value);
    if (event.target.value === "No") {
      navigate("/medications");
    }
  }

  const onSkip = () => {
    setInsurance("NO");
    navigate("/medications");
  };

  const NumberVerify = () => {
    navigate("/otpVerify");
  };

  const Insurance = () => {
    navigate("/insurance");
  };

  const CopayProgram = () => {
    navigate("/CopayProgram");
  };

  const FindPharmacy = () => {
    navigate("/findPharmacy");
  };

  const submit = () => {
    // navigate(`/medications`, { state: state });
  };

  const validationSchema = Yup.object().shape({
    bin: Yup.string().required("Bin is required"),
    // username: Yup.string()
    //     .required('Username is required')
    //     .min(6, 'Username must be at least 6 characters')
    //     .max(20, 'Username must not exceed 20 characters'),
    // email: Yup.string().required('Email is required').email('Email is invalid'),
    pcn: Yup.string().required("PCN is required"),
    groupNumber: Yup.string().required("Group number is required"),
    memberId: Yup.string().required("Installment Id is required"),
    // confirmPassword: Yup.string()
    //     .required('Confirm Password is required')
    //     .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  });

  const formik = useFormik({
    initialValues: {
      bin: "",
      pcn: "",
      groupNumber: "",
      memberId: "",
    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      let body = {
        bin: data?.bin,
        pcn: data?.pcn,
        groupNumber: data?.groupNumber,
        memberId: data?.memberId,
        appUserSeqNo: userDetail?.appUserSeqNo,
      };
      // console.log(JSON.stringify(data, null, 2));
      dispatch(saveInsurance(body))
        .unwrap()
        .then((response) => {
          //console.log(response);
          if (response?.data) {
            // addToast("Insurance has been added successfully.", {
            //   appearance: "success",
            //   autoDismiss: 5000,
            // });

            successToast("Insurance has been added successfully.");
            navigate("/medications");
          } else if (response?.message != "OK") {
            // addToast(response?.message, {
            //   appearance: "error",
            //   autoDismiss: 5000,
            // });

            errorToast(response?.message);
          }
        })
        .catch((e) => {
          //console.log(e);
        });
    },
  });

  return (
    <>
      <section className="fadeAni">
        <div className="row ">
          <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div
              className="medicine_icon pharma_icon"
              onClick={() => Insurance()}
            >
              <MotionIcon><i className="fas fa-angle-left"></i></MotionIcon>
            </div>
          </div>
        </div>
        <div className="margintop10">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-0">
              <div className="vector-image">
                <img src={Vector} className="img-fluid Vector" />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-4 paddingZero">
              <div className="home_one">
                <div className="insurance_inner_main">
                  <form onSubmit={formik.handleSubmit} id="loginID">
                    <div className="row" id="cc-info-div">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="insurance_inner">
                          <div className="row webView">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="page_counter">
                                <p onClick={() => NumberVerify()}>1</p>
                                <p onClick={() => Insurance()}>2</p>
                                <p
                                  onClick={() => CopayProgram()}
                                  className="active"
                                >
                                  3
                                </p>
                                <p onClick={() => FindPharmacy()}>4</p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                              <h1>
                                <b>A copay program is suggested for you</b>
                              </h1>
                              <h6>
                                <b>
                                  Copay programs are different in x y and z.
                                  Please make sure that your pharmacist know
                                  that you’d like to use the right program
                                </b>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      id="cc-info-div-smbt"
                      onClick={() => FindPharmacy()}
                    >
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <MotionButton>
                          <button className="btn_success" type="submit">
                            I Understand
                          </button>
                        </MotionButton>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="row mobileView">
          <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
            <div className="dot-main">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot dotActive"></span>
              <span className="dot "></span>
            </div>
          </div>
        </div>
      </section >
    </>
  );
};
