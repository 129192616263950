import React, { useEffect, useRef, useState } from 'react';
import Modal from "react-bootstrap/Modal"
import { useDispatch } from 'react-redux';
import { MotionIcon } from '../motion/MotionButton';
import { MotionTextH2 } from '../motion/MotionText';
import { MotionButton } from "../motion/MotionButton";
import { fetchSavingHistory, setSavingsHistory } from '../../reducers/medication';
import { store } from "../../store";
import cancel from "../../assets/images/RxLink assets/new assets/regular x.svg"
import { useFormik } from "formik";
import * as Yup from "yup";
import { motion, AnimatePresence, color } from "framer-motion";
import tabHealth from "../../assets/images/tab-health.svg";
import { genderOptions } from "./content";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router-dom";
import { addOrUpdatePatient } from '../../reducers/patient';
import { successToast } from '../toast/toast';
import DateOfBirth from '../../utilities/DateOfBirth';
import { useGetUser } from '../../utilities/useGetUser';

export default function EditPatient({ isOpen, handleClose, patient, setPatient }) {

    const pickerRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useGetUser();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (pickerRef?.current)
            pickerRef.current.input.readOnly = true;
    }, [pickerRef])

    useEffect(() => {
        if (patient && isOpen) {
            formik.setTouched(false);
            patient.firstName && formik.setFieldValue("firstName", patient?.firstName);
            patient.lastName && formik.setFieldValue("lastName", patient?.lastName);
            patient.middleName && formik.setFieldValue("middleName", patient?.middleName);
            patient.gender && formik.setFieldValue("gender", patient?.gender);
            patient.memberId && formik.setFieldValue("memberId", patient?.memberId);
            patient.groupNumber && formik.setFieldValue("groupNumber", patient?.groupNumber);
            patient.bin && formik.setFieldValue("bin", patient?.bin);
            patient.pcn && formik.setFieldValue("pcn", patient?.pcn);
            if (/^\d{8}$/.test(patient.dob) && parseInt(patient?.dob)) {
                const year = parseInt(patient?.dob.substr(0, 4));
                const month = parseInt(patient?.dob.substr(4, 2)) - 1;
                const day = parseInt(patient?.dob.substr(6, 2));
                const convertedDate = new Date(year, month, day);
                formik.setFieldValue("dob", convertedDate)
            }
        }

    }, [isOpen])

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required("Required"),
        middleName: Yup.string(),
        lastName: Yup.string().required("Required"),
        gender: Yup.string().required("Required"),
        dob: Yup.string().required("Required"),
        bin: Yup.string(),
        pcn: Yup.string(),
        groupNumber: Yup.string(),
        memberId: Yup.string()
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            middleName: "",
            lastName: "",
            dob: "",
            gender: "",
            bin: "",
            pcn: "",
            groupNumber: "",
            memberId: "",
        },
        validationSchema,
        onSubmit: (data) => {
            if (loading) return;
            let body = {
                firstName: data?.firstName,
                middleName: data?.middleName,
                lastName: data?.lastName,
                dob: String(data?.dob.getFullYear()) + String(data?.dob.getMonth() + 1).padStart(2, '0') + String(data?.dob.getDate()).padStart(2, '0'),
                gender: data?.gender,
                bin: data?.bin,
                pcn: data?.pcn,
                groupNumber: data?.groupNumber,
                memberId: data?.memberId,
                appUserSeqNo: user?.appUserSeqNo,
                domainName: "Clinical",
                patientSeqNo: patient?.patientSeqNo
            };
            setLoading(true);
            try {
                dispatch(addOrUpdatePatient(body)).unwrap().then((res) => {
                    if (res?.data) {
                        setPatient(res?.data);
                        successToast(res?.message);
                        handleClose(false);
                        setTimeout(() => {
                            setLoading(false);
                        }, 200);
                    }
                });
            } catch (e) {
                setLoading(false);
            }

        },
    });

    return (
        <Modal dialogClassName="modal-lg" show={isOpen} onHide={handleClose} keyboard={false} centered style={{ justifyContent: "center", }}>
            <Modal.Body className=""  >
                <div className='container' style={{ maxWidth: "100%", padding: 6 }}>
                    <div className="row">
                        <div className='col-8'><span style={{ paddingTop: 0, fontSize: 16 }}>Edit patient</span></div>
                        <div className='col-4'>
                            <button
                                className="crossButton"
                                onClick={handleClose}
                                type="button"
                                style={{ height: 28, width: 28, paddingRight: 17 }}
                            >
                                <img src={cancel} />
                            </button>
                        </div>

                    </div>
                    <div className="">
                        <div className="hrDiv hrDivpadding" style={{ width: "98.6%" }}></div>
                    </div>
                    <form id="loginID">
                        <div className="row" id="cc-info-div" style={{ padding: "0px 11px" }}>
                            <motion.div
                                className="row g-1"
                                // initial={{ x: 300, opacity: 0 }}
                                // animate={{ x: 0, opacity: 1 }}
                                // transition={{ duration: 0.8, delay: 0.25 }}
                                // exit={{ x: 300 }}
                                style={{ marginTop: 0 }}
                            >

                                <span style={{ fontSize: 11, fontWeight: "bold" }}>PERSONAL INFORMATION</span>
                                <div className="col-sm-12 col-md-4 col-lg-4 form-group">
                                    <input
                                        type="text"
                                        name="firstName"
                                        className={
                                            "form-control  custom-form-control" +
                                            (formik.errors.firstName && formik.touched.firstName
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="First Name*"
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                    />
                                    <div className="invalid-feedback">
                                        {" "}
                                        {formik.errors.firstName && formik.touched.firstName
                                            ? formik.errors.firstName
                                            : null}{" "}
                                    </div>
                                </div>
                                <div className=" col-sm-12 col-md-4 col-lg-4 form-group">
                                    <input
                                        type="text"
                                        name="middleName"
                                        className={
                                            "form-control  custom-form-control" +
                                            (formik.errors.middleName && formik.touched.middleName
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Middle Name"
                                        onChange={formik.handleChange}
                                        value={formik.values.middleName}
                                    />
                                    <div className="invalid-feedback">
                                        {" "}
                                        {formik.errors.middleName && formik.touched.middleName
                                            ? formik.errors.middleName
                                            : null}
                                    </div>
                                </div>
                                <div className=" col-sm-12 col-md-4 col-lg-4 form-group">
                                    <input
                                        type="text"
                                        name="lastName"
                                        className={
                                            "form-control  custom-form-control" +
                                            (formik.errors.lastName &&
                                                formik.touched.lastName
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Last Name*"
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                    />
                                    <div className="invalid-feedback">
                                        {" "}
                                        {formik.errors.lastName &&
                                            formik.touched.lastName
                                            ? formik.errors.lastName
                                            : null}
                                    </div>
                                </div>
                            </motion.div>
                            <motion.div
                                className="row g-1"
                            // initial={{ x: 300, opacity: 0 }}
                            // animate={{ x: 0, opacity: 1 }}
                            // transition={{ duration: 0.8, delay: 0.25 }}
                            // exit={{ x: 300 }}
                            >
                                <div className=" col-sm-12 col-md-6 col-lg-6 form-group">
                                    <select
                                        name="gender"
                                        defaultValue=""
                                        style={{ color: formik.values.gender === "" && "rgba(0, 0, 0, 0.35)" }}

                                        className={
                                            "form-select  custom-form-control custom-form-select" +
                                            (formik.errors.gender && formik.touched.gender
                                                ? " is-invalid form-select-is-invalid"
                                                : "")
                                        }
                                        onChange={formik.handleChange}
                                        value={formik.values.gender}
                                    >
                                        <option value="" hidden disabled defaultValue="">Birth Sex*</option>
                                        {genderOptions.map(option => (
                                            <option key={option.value} value={option.value} style={{ color: "black" }}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">
                                        {" "}
                                        {formik.errors.gender && formik.touched.gender
                                            ? formik.errors.gender
                                            : null}{" "}
                                    </div>
                                </div>
                                <div className=" col-sm-12 col-md-6 col-lg-6 form-group">
                                    <div className="date-picker-container">
                                        {/* <DatePicker
                                            ref={pickerRef}
                                            name="dob"
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            maxDate={new Date()}
                                            className={
                                                "form-control  custom-form-control" +
                                                (formik.errors.dob && formik.touched.dob
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            onChange={date => {
                                                formik.setFieldValue('dob', date)
                                            }}
                                            selected={formik.values.dob}
                                            placeholderText="Date of Birth*"
                                        /> */}
                                        <DateOfBirth formik={formik} fieldName={'dob'} enabled={true} />

                                    </div>
                                    {/* <div className="date-picker-feedback invalid-feedback">
                                        {" "}
                                        {formik.errors.dob && formik.touched.dob
                                            ? formik.errors.dob
                                            : null}{" "}
                                    </div> */}
                                </div>
                            </motion.div>
                            <div className="mt-1">
                                <div className="hrDiv hrDivpadding" style={{ width: "103%", marginLeft: -8 }}></div>
                            </div>
                            <motion.div
                                className="row g-1"
                                // initial={{ x: 300, opacity: 0 }}
                                // animate={{ x: 0, opacity: 1 }}
                                // transition={{ duration: 0.8, delay: 0.25 }}
                                // exit={{ x: 300 }}
                                style={{ marginTop: 0 }}
                            >
                                <span style={{ fontSize: 11, fontWeight: "bold" }}>INSURANCE</span>
                                <div className=" col-sm-12 col-md-3 col-lg-3 form-group">
                                    <input
                                        type="text"
                                        name="bin"
                                        className={
                                            "form-control  custom-form-control" +
                                            (formik.errors.bin && formik.touched.bin
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="BIN"
                                        onChange={formik.handleChange}
                                        value={formik.values.bin}
                                    />
                                    <div className="invalid-feedback">
                                        {" "}
                                        {formik.errors.bin && formik.touched.bin
                                            ? formik.errors.bin
                                            : null}{" "}
                                    </div>
                                </div>
                                <div className=" col-sm-12 col-md-3 col-lg-3 form-group">
                                    <input
                                        type="text"
                                        name="pcn"
                                        className={
                                            "form-control  custom-form-control" +
                                            (formik.errors.pcn && formik.touched.pcn
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="PCN"
                                        onChange={formik.handleChange}
                                        value={formik.values.pcn}
                                    />
                                    <div className="invalid-feedback">
                                        {" "}
                                        {formik.errors.pcn && formik.touched.pcn
                                            ? formik.errors.pcn
                                            : null}
                                    </div>
                                </div>
                                <div className=" col-sm-12 col-md-3 col-lg-3 form-group">
                                    <input
                                        type="text"
                                        name="groupNumber"
                                        className={
                                            "form-control  custom-form-control" +
                                            (formik.errors.groupNumber &&
                                                formik.touched.groupNumber
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Group"
                                        onChange={formik.handleChange}
                                        value={formik.values.groupNumber}
                                    />
                                    <div className="invalid-feedback">
                                        {" "}
                                        {formik.errors.groupNumber &&
                                            formik.touched.groupNumber
                                            ? formik.errors.groupNumber
                                            : null}
                                    </div>
                                </div>
                                <div className=" col-sm-12 col-md-3 col-lg-3 form-group">
                                    <input
                                        type="text"
                                        name="memberId"
                                        className={
                                            "form-control  custom-form-control" +
                                            (formik.errors.memberId &&
                                                formik.touched.memberId
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Member ID"
                                        onChange={formik.handleChange}
                                        value={formik.values.memberId}
                                    />
                                    <div className="invalid-feedback">
                                        {" "}
                                        {formik.errors.memberId &&
                                            formik.touched.memberId
                                            ? formik.errors.memberId
                                            : null}
                                    </div>
                                </div>
                            </motion.div>
                        </div>

                        {/* <div>
                        <p style={{ fontSize: 16 }}>Viewing your past savings will erase any savings currently selected would you like to proceed anyway?</p>
                    </div> */}
                        <div className="row">
                            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                                <div className="mt-20">
                                    <MotionButton>
                                        <button
                                            className="btn_success"
                                            //    type="submit"
                                            onClick={formik.handleSubmit}
                                        >
                                            Confirm
                                        </button>
                                    </MotionButton>
                                </div>
                            </div>
                            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                                <div className="mt-20">
                                    <MotionButton>
                                        <button
                                            type="button"
                                            className="btn_default"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </button>
                                    </MotionButton>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>
    )
}
