import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    EnrollmentList : [],
    ViewPatient: 'PatientData'
  };
  

export const fetchEnrollmentList = createAsyncThunk("EnrollmentList", (body) =>
    httpClientJava
    .post(`patient/enrollmentList`, body)
    .then(({data}) => {
        return data;
    })
    .catch(error => error)
);


export const Enrollment = createSlice({
    name: "Enrollment",
    initialState,
    // reducers: {
      
    // },
    reducers: {
      setEnrollmentList: (state, action) => {
        state.EnrollmentList = action.payload;
      },
      setViewPatient: (state, action) => {
        state.ViewPatient = action.payload;
      },
        [fetchEnrollmentList.pending.type]: (state, action) => {
          state.EnrollmentList = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [fetchEnrollmentList.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.EnrollmentList = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [fetchEnrollmentList.rejected.type]: (state, action) => {
          state.EnrollmentList = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setEnrollmentList, setViewPatient } = Enrollment.actions;

export default Enrollment.reducer;