import { useEffect, useState, useRef } from "react";
import wallet from "../../assets/images/wallet.png";
import walletGoogle from "../../assets/images/g-wallet.png";
import InputMask from "react-input-mask";
import validator from "validator";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { membersAssign } from "../../reducers/assignMembers";
import Modal from "react-bootstrap/Modal";
import { sndSMSOrEmail } from "../../reducers/sendSMSOrEmail";
import { appleWalletAPI } from "../../reducers/appleWallet";
import { setSMSOrEmailResponse } from "../../reducers/sendSMSOrEmail";
import { setFromCoupon, setShowCoupon, setPencilClick } from "../../reducers/medication";

import "react-toastify/dist/ReactToastify.css";
import Header from "../partials/Header";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import {
  isBrowser,
  isMobile,
  isIOS,
  isDesktop,
  isAndroid,
  isMacOs,
} from "react-device-detect";
import edit from "../../assets/images/RxLink assets/new assets/edit pen.svg";
import avatar from "../../assets/images/RxLink assets/avatar icon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MotionButton, MotionIcon } from "../motion/MotionButton";
import { MotionText, MotionTextH2 } from "../motion/MotionText";
import { SendSMSOrEmailModalWeb } from "./Modals/SendSMSorEmailModal"
import bestOffer from "../../assets/images/best-offer.png";
import cheaper from "../../assets/images/cheaper.png";
import { successToast, errorToast } from "../toast/toast";

import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import { useStyleMediaQuery } from "./hooks";
import { searchPharmacyLogo } from "../PharmacyLogo";

const CouponCard = (props) => {
  var userDetail = JSON.parse(localStorage.getItem("user"));

  const { windowWidth, cheaperOptionAvailable } = props;

  const pharmacyState = JSON.parse(sessionStorage.getItem('selectMapValues'));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { state } = useLocation();

  const state = useSelector((state) => state.medication.couponData);


  const [pharmacistPara, setPharmacistPara] = useState(false);
  const [url, setUrl] = useState("");
  const [isVisibleSMSModal, setIsVisibleSMSModal] = useState(false);
  const [memberAssign, setMemberAssign] = useState("");
  const [isComingSoon, setIsComingSoon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isEmailValue, setEmailValue] = useState();
  const [emailError, setEmailError] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailOrSMSSuccess, setIsEmailOrSMSSuccess] = useState(false)
  const [termsSMSorEmail, setTermsSMSorEmail] = useState(false)
  const [termsError, setTermsError] = useState(false)
  const { matches: isTab } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 991 });

  var patientSeqNo = JSON.parse(localStorage.getItem("patientSeqNo"))


  const memberInfo = useSelector((state) => state.sendSMSOrEmailAPI.memberInfo);

  const tooltipOfferRef = useRef(null);


  const isUpenn = window.location.href.includes("upenn");
  const isPapaJohn = window.location.href.includes("papajohns") || window.location.href.includes("pju");
  const isTripment = window.location.href.includes("tripment");
  const isHouston = window.location.href.includes("hhc");
  const isLcmc = window.location.href.includes("lcmc");

  const healthSystem = isUpenn ? "Upenn Medicine" :
    isPapaJohn ? "Papa John's United" :
      isTripment ? "Tripment Health" :
        isHouston ? "Housten Health System" :
          isLcmc ? "LCMC Health" :
            "RxLink";


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
    } else {
      errorToast("404 not found");
      navigate("/");
    }
  }, []);

  const navigateToCouponSent = () => {
    setIsEmailOrSMSSuccess(true)
  }

  const termsHandler = (e) => {
    setTermsSMSorEmail(e.target.checked)
    if (e.target.checked) {
      setTermsError(false)
    }
  }


  // const [isNotification, setNotification] = useState(false);
  // const [isNotificationMessage, setNotificationMessage] = useState({});

  // useEffect(() => {
  //   setLoading(true);
  //   let body = {
  //     appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
  //     pharmacyName: state?.drug?.pharmacyName,
  //     price: "$" + state?.drug?.cost,
  //     drug: {
  //       name: state?.medications[0]?.selectedDrug?.name,
  //       dosage: state?.medications[0]?.selectedDrug?.dosage,
  //       form: state?.medications[0]?.selectedDrug?.form,
  //       default_quantity: state?.medications[0]?.selectedDrug?.default_quantity,
  //     },
  //     memberInfo: memberInfo ? memberInfo : null,
  //   };
  //   dispatch(appleWalletAPI(body))
  //     .unwrap()
  //     .then((response) => {
  //       setLoading(false);

  //       if (response?.status === "OK") {
  //         // addToast(response?.message, {appearance: "success",autoDismiss: 5000,});
  //         setIamHere(response.data.memberInfo);
  //         console.log("qwerty" + response.data.memberInfo);
  //         dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
  //         localStorage.setItem(
  //           "memberInfo",
  //           JSON.stringify(response?.data?.memberInfo)
  //         );
  //         //  const walletUrl = response?.data?.walletUrl;
  //         console.log(response?.data?.walletUrl);
  //         setUrl(response?.data?.walletUrl);
  //         //  window.open(walletUrl, '_blank')?.focus();
  //       } else {
  //         addToast(response?.message, {
  //           appearance: "error",
  //           autoDismiss: 5000,
  //         });
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setLoading(false);
  //     });
  // }, []);

  const validateEmail = (e) => {
    var email = e.target.value;
    setEmailValue(email);
    setEmail(e.target.value);
    if (validator.isEmail(email)) {
      setEmailError("");
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
      setEmailError("Enter valid Email");
    }
  };
  const sendSMS = (data) => {
    const { lat, lng, postalCode } = props.ownPosition;
    if (termsSMSorEmail) {
      setTermsError(false)
      if (data) {

        const drugArr = [];
        for (let index = 0; index < state?.medDrugData?.length; index++) {
          let med = state.medDrugData[index];
          let genericNameExist = state?.medDrugData[index]?.medicationAPIRes?.names[0] == state?.medDrugData[index]?.selectedDrug?.name ? state?.medDrugData[index]?.medicationAPIRes?.names[1] : state?.medDrugData[index]?.medicationAPIRes?.names[0];

          const getName = () => {
            if (state?.medDrugData[index]?.selectedDrug?.type == "Generic") {
              if (state?.medDrugData[index]?.medicationAPIRes?.names[0] == state?.medDrugData[index]?.selectedDrug?.name) {
                return state?.medDrugData[index]?.medicationAPIRes?.names[1] !== undefined ? state?.medDrugData[index]?.medicationAPIRes?.names[1] + ` (${state?.medDrugData[index]?.medicationAPIRes?.names[0]})` : state?.medDrugData[index]?.medicationAPIRes?.names[0]
              }
              else {
                return state?.medDrugData[index]?.medicationAPIRes?.names[0] + ` (${state?.medDrugData[index]?.medicationAPIRes?.names[1]})`
              }
            }
            else {
              if (genericNameExist != undefined)
                return state?.medDrugData[index]?.selectedDrug?.name + ` (${genericNameExist})`
              else
                return state?.medDrugData[index]?.selectedDrug?.name

            }
          }
          let tempBody = {
            name: getName(),
            dosage: state?.medDrugData[index]?.selectedDrug?.dosage,
            form: state?.medDrugData[index]?.selectedDrug?.form,
            price: med?.bestPrice,
            default_quantity:
              state?.medDrugData[index]?.selectedDrug?.default_quantity,
            cardInfo: {
              pcn: med.rxSense ? rxSenseCard?.pcn : med.hasInsurance ? med.InsuranceInfo.pcn : state.memberAssign.pcn,
              bin: med.rxSense ? rxSenseCard?.bin : med.hasInsurance ? med.InsuranceInfo.bin : state.memberAssign.bin,
              memberId: med.rxSense ? rxSenseCard?.memberId : med.hasInsurance ? med.InsuranceInfo.ID : state.memberAssign.member_id,
              groupId: med.rxSense ? rxSenseCard?.groupId : med.hasInsurance ? med.InsuranceInfo.Group : state.memberAssign.group_id,
              hasInsurance: med.hasInsurance,
            }
          };
          drugArr[index] = tempBody;
        }

        setLoading(true);

        let body = {
          sendSMS: true,
          mobileNo: data?.username.match(numberPattern).join(""),
          appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
          patientSeqNo: patientSeqNo,
          memberInfo: state?.memberAssign ? state?.memberAssign : null,
          longitude: pharmacyState.lng || props?.ownPosition?.lng,
          latitude: pharmacyState.lat || props?.ownPosition?.lat,
          pharmacyName: state.drug.pharmacyName,
          drugs: drugArr,
          state: pharmacyState?.state
        };
        dispatch(sndSMSOrEmail(body))
          .unwrap()
          .then((response) => {
            setLoading(false);

            if (response?.status === "OK") {
              setIsSMS(false);
              navigateToCouponSent();
              setTermsSMSorEmail(false);
              // addToast(response?.message, {
              //   appearance: "success",
              //   autoDismiss: 5000,
              // });
              successToast(response?.message);
              dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
              setIsVisibleSMSModal(false);
              localStorage.setItem(
                "memberInfo",
                JSON.stringify(response?.data?.memberInfo)
              );
              // localStorage.setItem("smsWalletUrl",JSON.stringify(response?.data?.walletUrl));
              setUrl(response?.data?.walletUrl);
            } else {
              errorToast(response?.message);
            }
          })
          .catch((e) => {
            //console.log(e);
            setLoading(false);
          });
      }
    } else {
      setTermsError(true)
    }
  };

  const sendEmail = (flag) => {
    const { lat, lng, postalCode } = props.ownPosition;
    if (!isEmailValue) {
      setEmailError("Email is required");
      return;
    }

    if (!isValidEmail) {
      setEmailError("Enter valid email");
      return;
    }

    if (termsSMSorEmail) {
      setTermsError(false)
    } else {
      setTermsError(true)
      return;
    }


    console.log({ props })
    if (flag) {
      setLoading(true);
      const drugArr = [];
      for (let index = 0; index < state?.medDrugData.length; index++) {
        let med = state.medDrugData[index];
        let genericNameExist = state?.medDrugData[index]?.medicationAPIRes?.names[0] == state?.medDrugData[index]?.selectedDrug?.name ? state?.medDrugData[index]?.medicationAPIRes?.names[1] : state?.medDrugData[index]?.medicationAPIRes?.names[0];

        const getName = () => {
          if (state?.medDrugData[index]?.selectedDrug?.type == "Generic") {
            if (state?.medDrugData[index]?.medicationAPIRes?.names[0] == state?.medDrugData[index]?.selectedDrug?.name) {
              return state?.medDrugData[index]?.medicationAPIRes?.names[1] !== undefined ? state?.medDrugData[index]?.medicationAPIRes?.names[1] + ` (${state?.medDrugData[index]?.medicationAPIRes?.names[0]})` : state?.medDrugData[index]?.medicationAPIRes?.names[0]
            }
            else {
              return state?.medDrugData[index]?.medicationAPIRes?.names[0] + ` (${state?.medDrugData[index]?.medicationAPIRes?.names[1]})`
            }
          }
          else {
            if (genericNameExist != undefined)
              return state?.medDrugData[index]?.selectedDrug?.name + ` (${genericNameExist})`
            else
              return state?.medDrugData[index]?.selectedDrug?.name

          }
        }
        let tempBody = {
          name: getName(),
          dosage: state?.medDrugData[index]?.selectedDrug?.dosage,
          form: state?.medDrugData[index]?.selectedDrug?.form,
          price: med?.bestPrice,
          default_quantity:
            parseInt(state?.medDrugData[index]?.selectedDrug?.default_quantity),
          cardInfo: {
            pcn: med.rxSense ? rxSenseCard?.pcn : med.hasInsurance ? med.InsuranceInfo.pcn : state.memberAssign.pcn,
            bin: med.rxSense ? rxSenseCard?.bin : med.hasInsurance ? med.InsuranceInfo.bin : state.memberAssign.bin,
            memberId: med.rxSense ? rxSenseCard?.memberId : med.hasInsurance ? med.InsuranceInfo.ID : state.memberAssign.member_id,
            groupId: med.rxSense ? rxSenseCard?.groupId : med.hasInsurance ? med.InsuranceInfo.Group : state.memberAssign.group_id,
            hasInsurance: med.hasInsurance,
          }
        };
        drugArr[index] = tempBody;
      }
      let body = {
        sendSMS: false,
        appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
        patientSeqNo: patientSeqNo,
        email: email.match(emailPattern).join(""),
        pharmacyName: state?.drug?.pharmacyName,
        price: "$" + state?.drug?.bestPrice.toFixed(2),

        drugs: drugArr,
        // memberInfo: state?.memberAssign ? state?.memberAssign : null,
        longitude: pharmacyState.lng || props?.ownPosition?.lng,
        latitude: pharmacyState.lat || props?.ownPosition?.lat,
        state: pharmacyState?.state
      };
      dispatch(sndSMSOrEmail(body))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.status === "OK") {
            setIsEmail(false);
            navigateToCouponSent()
            setEmailValue("");
            setTermsSMSorEmail(false)
            // addToast(response?.message, {
            //   appearance: "success",
            //   autoDismiss: 5000,
            // });

            successToast(response?.message);
            dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
            localStorage.setItem(
              "memberInfo",
              JSON.stringify(response?.data?.memberInfo)
            );
          } else {
            // addToast(response?.message, {
            //   appearance: "error",
            //   autoDismiss: 5000,
            // });
            setEmailValue("");
          }
        })
        .catch((e) => {
          //console.log(e);
          setLoading(false);
        });
    }
  };

  const appleWallet = () => {
    const { lat, lng, postalCode } = props.ownPosition;
    if (JSON.parse(localStorage.getItem("smsWalletUrl"))) {
      const walletUrl = JSON.parse(localStorage.getItem("smsWalletUrl"));
      // console.log(walletUrl);
      //  window.open(walletUrl, '_blank')?.focus();
    } else {
      setLoading(true);
      let body = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user")).appUserSeqNo,
        patientSeqNo: patientSeqNo,
        pharmacyName: state?.drug?.pharmacyName,
        price: "$" + state?.drug?.cost,
        drug: {
          name: state?.medications[0]?.selectedDrug?.name,
          dosage: state?.medications[0]?.selectedDrug?.dosage,
          form: state?.medications[0]?.selectedDrug?.form,
          default_quantity:
            state?.medications[0]?.selectedDrug?.default_quantity,
        },
        memberInfo: state?.memberAssign ? state?.memberAssign : null,
        longitude: lng,
        latitude: lat,
      };
      dispatch(appleWalletAPI(body))
        .unwrap()
        .then((response) => {
          setLoading(false);

          if (response?.status === "OK") {
            // addToast(response?.message, {appearance: "success",autoDismiss: 5000,});
            dispatch(setSMSOrEmailResponse(response?.data?.memberInfo));
            localStorage.setItem(
              "memberInfo",
              JSON.stringify(response?.data?.memberInfo)
            );
            var walletUrl = response?.data?.walletUrl;
            //console.log(walletUrl);
            //  window.open(walletUrl, '_blank')?.focus();
          } else {
            // addToast(response?.message, {
            //   appearance: "error",
            //   autoDismiss: 5000,
            // });
            errorToast(response?.message);
          }
        })
        .catch((e) => {
          //console.log(e);
          setLoading(false);
        });
    }
  };

  const IMHere = (e) => {
    setPharmacistPara(e);
    if (state?.isPharmacyInsShow) {
      navigate("/medications");
    } else {
      return;
    }
    if (JSON.parse(localStorage.getItem("memberInfo"))) {
      setMemberAssign(JSON.parse(localStorage.getItem("memberInfo")));
    } else {
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
      };
      //let temp = "{\"member_id\":\"RKM7JDPFWW\", \"group_id\":\"002\", \"pcn\":\"CHIPPO\", \"bin\":\"019876\", \"phone\":\"\", \"phone_active\":true, \"email\":\"\", \"email_active\":true, \"attributes\":{}, \"links\":[{\"rel\":\"member_card_image\", \"href\":\"https://card.hippo.tools/image.png?member_id=RKM7JDPFWW&group=002&pcn=CHIPPO&bin=019876\"}, {\"rel\":\"member_card_website\", \"href\":\"https://hellohippo.com/add-to-wallet?member_id=RKM7JDPFWW\"}, {\"rel\":\"member_card_apple\", \"href\":\"https://wallet.hippo.tools?member_id=RKM7JDPFWW&group=002&pcn=CHIPPO&bin=019876\"}, {\"rel\":\"member_card_google\", \"href\":\"https://gwallet.hippo.tools/pass/redirect?member_id=RKM7JDPFWW\"}]}"
      //setMemberAssign(JSON.parse(temp));
      //console.log(JSON.parse(temp));
      setLoading(true);
      dispatch(membersAssign(body))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.data) {
            setMemberAssign(JSON.parse(response?.data));
          }
        })
        .catch((e) => {
          //console.log(e);
          setLoading(false);
        });
    }
  };

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    // setAuth(null);
    navigate("/");
  }

  const medications = () => {
    dispatch(setFromCoupon(true));
    dispatch(setShowCoupon(false))
    // navigate("/medications");
  };

  const goMedications = () => {
    let obj = {
      pencilClick: true,
    };
    dispatch(setShowCoupon(false))
    dispatch(setPencilClick(true));
    navigate("/medications", { state: obj });
  };

  useEffect(() => {
    setPharmacistPara(state?.isPharmacyInsShow);
  }, [props.isSaveValueForCoupon]);

  var numberPattern = /\d+/g;
  var emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Phone number is required"),
    // username: Yup.string()
    //     .required('Username is required')
    //     .min(6, 'Username must be at least 6 characters')
    //     .max(20, 'Username must not exceed 20 characters'),
    // email: Yup.string().required('Email is required').email('Email is invalid'),

    // confirmPassword: Yup.string()
    //     .required('Confirm Password is required')
    //     .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      terms: true,
    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      sendSMS(data);
    },
  });

  const openSMSpopup = (e) => {
    setIsSMS(e);
    formik.values.username = "";
  };
  const openEmailPopup = (e) => {
    setIsEmail(e);
    // formik.values.username = ""
    setEmailError("");
  };

  const closeSMSOrEmailPopup = (e) => {
    setTermsError(false)
    setTermsSMSorEmail(false)
    setIsEmailOrSMSSuccess(e);
    formik.setTouched({}, false);
    setIsSMS(e);
    setIsEmail(e);
  }

  const rxSenseCard = JSON.parse(localStorage.getItem("rxSenseCard"));

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <section className=" mt-3">
            {!pharmacistPara && (
              <div className="container">
                <div className="webView">
                  <div className="row ">
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div
                        className="medicine_icon pharma_icon back_icon"
                        onClick={() => medications()}
                      >
                        <MotionIcon><i className="fas fa-angle-left"></i></MotionIcon>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="medicine_icon medicine_icon_m">
                        {/* <MotionIcon> */}
                        <motion.img
                          whileHover={{ scale: 1.1 }}
                          src={avatar}
                          className="img-fluid pointer"
                          id="dropdownMenuButton1"
                          // data-bs-toggle="dropdown"
                          aria-expanded="false"
                          type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                        ></motion.img>
                        {/* </MotionIcon> */}
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            className="dropdown-item item-1"
                            onClick={() => logout()}
                          >
                            Logout
                          </a>
                        </div>
                        {/* <i className="fa fa-user"></i> */}
                      </div>
                    </div>
                  </div>
                </div>
                {state && (
                  <div className="webView showDivonWeb">
                    <div className="row ">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="medication-heading">
                          <MotionTextH2 text="My medication savings" duration={0.03} />
                          {/* <h2>My medication savings</h2> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {state && (
                  <div className="row coupon_main">
                    <div className="col-sm-12 col-md-12 col-lg-3 mobileView">
                      <div className="medications_inner medications_inner_coupon dlr-Sup  medications_inner_imhere ">
                        <div className="row">
                          <div className="col-6 col-sm-6 col-md-6 col-lg-12 ">
                            <label className="">TOTAL OUT OF POCKET </label>
                            <div className="med-Flex">
                              <h1 className="webView">
                                <sup>
                                  <b>$&nbsp;</b>
                                </sup>
                                {state?.drug?.bestPrice.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}{" "}
                              </h1>
                              <h3 className="mobileView">
                                <sup>
                                  <b>$&nbsp;</b>
                                </sup>
                                {state?.drug?.bestPrice.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}{" "}
                              </h3>
                              <p className="font13 flex-end">
                                {Math.round(state?.savingPercentage)}
                                %&nbsp;off{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-6 col-sm-6 col-md-6 col-lg-12 autoMargin">
                            <MotionButton>
                              <button
                                className="btn_default"
                                onClick={() => medications()}
                              >
                                Hide coupons
                              </button>
                            </MotionButton>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {/* <hr></hr> */}
                            <label className="hrDiv">PHARMACYs</label>
                            <div className="review-coupon ">
                              <div className="row ">
                                <div className="col-3 col-sm-3 col-md-3 col-lg-3 autoMargin">
                                  <div className="pharmacyImg_div">
                                    <img
                                      src={state?.drug?.logo?.small}
                                      className="img-fluid "
                                    />
                                  </div>
                                </div>
                                <div className="col-7 col-sm-7 col-md-7 col-lg-7 autoMargin">
                                  <div className="">
                                    <p className="shopName">
                                      {" "}
                                      {state?.drug?.pharmacyName}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-3 col-sm-3 col-md-3 col-lg-3" style={{ paddingLeft: 10, paddingRight: 0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                                  {
                                    state.cheaperOptionAvailable ? <img src={cheaper} style={{ width: "16vw", height: 46 }} />
                                      : <img
                                        src={bestOffer}
                                        style={{ height: 46, width: "16vw" }}
                                      />
                                  }
                                </div> */}
                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 autoMargin text-center">
                                  <img
                                    className="cursor-pointer"
                                    src={edit}
                                    onClick={() => goMedications()}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row webView">
                              <div className="col-6 col-sm-6 col-md-12 col-lg-12 ">
                                <MotionButton>
                                  <button className="btn_default" onClick={() => medications()}>
                                    Hide coupons
                                  </button>
                                </MotionButton>
                                {/* <hr></hr> */}
                              </div>
                            </div>
                            <h4 className="row mb-2 mobileView">
                              Save and keep your coupons handy
                            </h4>
                            <motion.h4 className="row mb-2 webView"
                              initial={{ y: -30 }}
                              animate={{ y: 0 }}
                              transition={{ duration: 0.5 }}
                            >
                              Save your discounts to keep them handy
                            </motion.h4>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                              <motion.div className="col-6 col-sm-6 col-md-12 col-lg-12"
                                initial={{ y: -30 }}
                                animate={{ y: 0 }}
                                transition={{ duration: 0.5 }}
                              >
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    onClick={() => openSMSpopup(true)}
                                  >
                                    SMS
                                  </button>
                                </MotionButton>
                              </motion.div>
                              <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    onClick={() => openEmailPopup(true)}
                                  >
                                    Email
                                  </button>
                                </MotionButton>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-12 col-sm-5 col-md-12 col-lg-12">
                            <div className="wallet-img">
                              {isIOS && (
                                <img
                                  src={wallet}
                                  onClick={() => appleWallet()}
                                  className="img-fluid"
                                />
                              )}

                              {isAndroid && (
                                <img
                                  src={walletGoogle}
                                  onClick={() => appleWallet()}
                                  className="img-fluid"
                                />
                              )}
                            </div>
                          </div> */}
                          <div className="col-sm-12 col-md-12 col-lg-12 "></div>
                        </div>
                      </div>
                    </div>
                    <motion.div className="col-sm-12 col-md-12 col-lg-9 showDivonWeb"
                      initial={{ y: -50 }}
                      animate={{ y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <motion.div className="medications_inner medications_inner_coupon  dlr-Sup">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <h4 className="coupon_link">
                              Show the coupons and{" "}
                              <a>
                                <u
                                  className="pharmacyINC"
                                  onClick={() => IMHere(true)}
                                >
                                  pharmacy instructions
                                </u>
                              </a>{" "}
                              to your pharmacy staff
                            </h4>
                            <div className="container">
                              <div className="med_padding">
                                <div className="">
                                  {state?.medDrugData?.map((med, index) => {
                                    return (
                                      <>

                                        <h4 className="normal mt-3 mb-3">
                                          {med?.hasInsurance ? 'Use with insurance' : 'With RxLink Offer'}
                                        </h4>

                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <motion.div className="card_info_new "
                                            initial={{ y: -100 }}
                                            animate={{ y: 0 }}
                                            transition={{
                                              damping: 12,
                                              stiffness: 200,
                                              mass: 0.5,
                                              type: 'spring',
                                              duration: 0.3
                                            }}
                                          >
                                            <div className="row">
                                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                <div className="card_info_new_left">
                                                  <h3 className="webView">
                                                    {
                                                      med?.medicationAPIRes
                                                        ?.search_name
                                                    }
                                                  </h3>
                                                  <h4 className="mobileView">
                                                    {
                                                      med?.medicationAPIRes
                                                        ?.search_name
                                                    }
                                                  </h4>
                                                  <p className="mt-2">
                                                    {med?.selectedDosage}{med?.selectedDosage && ", "}
                                                    {med?.selectedQty}{" "}
                                                    {med?.selectedForm}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                                <div className="card_info_new_right">
                                                  {/* <h5>copay assistance</h5> */}
                                                  {med?.bestPrice ? (
                                                    <h3 className="mt-2 card_info__">
                                                      <b>$&nbsp;</b>
                                                      {med?.bestPrice?.toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                      })}
                                                    </h3>
                                                  ) : (
                                                    <h3 className="mt-2 card_info__">
                                                      ---
                                                    </h3>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="text-center">
                                                  <span>
                                                    &nbsp;
                                                    {/* current as of 10/12/2023 */}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </motion.div>
                                          <div className="car_info_bottom_new">
                                            <div className="row">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="couponTable">
                                                  <div className="row">
                                                    <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                      <div className="webView">
                                                        <label>BIN</label>
                                                        <h4 className="black-text insrance_card_bottom_label_black opacity_1">
                                                          {med?.hasInsurance ? med?.InsuranceInfo?.bin : med?.rxSense ? rxSenseCard?.bin :
                                                            state?.memberAssign
                                                              ?.bin
                                                          }
                                                        </h4>
                                                      </div>
                                                      <div className="mobileView">
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <label>BIN</label>
                                                          </div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                              {
                                                                med?.hasInsurance ? med?.InsuranceInfo?.bin : med?.rxSense ? rxSenseCard?.bin :
                                                                  state?.memberAssign
                                                                    ?.bin
                                                              }
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                      <div className="webView">
                                                        <label>PCN</label>
                                                        <h4 className="black-text opacity_1">
                                                          {" "}
                                                          {med?.hasInsurance ? med?.InsuranceInfo?.pcn : med?.rxSense ? rxSenseCard?.pcn :
                                                            state?.memberAssign
                                                              ?.pcn
                                                          }
                                                        </h4>
                                                      </div>
                                                      <div className="mobileView">
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <label>PCN</label>
                                                          </div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                              {med?.hasInsurance ? med?.InsuranceInfo?.pcn : med?.rxSense ? rxSenseCard?.pcn :
                                                                state?.memberAssign
                                                                  ?.pcn
                                                              }
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                      <div className="webView">
                                                        <label>GROUP</label>
                                                        <h4 className="black-text opacity_1">
                                                          {" "}
                                                          {med?.hasInsurance ? med?.InsuranceInfo?.Group : med?.rxSense ? rxSenseCard?.groupId :
                                                            state?.memberAssign
                                                              ?.group_id
                                                          }
                                                        </h4>
                                                      </div>
                                                      <div className="mobileView">
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <label>GROUP</label>
                                                          </div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                              {
                                                                med?.hasInsurance ? med?.InsuranceInfo?.Group : med?.rxSense ? rxSenseCard?.groupId :
                                                                  state?.memberAssign
                                                                    ?.group_id
                                                              }
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-6 col-sm-3 col-md-3 col-lg-3 ">
                                                      <div className="webView">
                                                        <label>ID</label>
                                                        <h4 className="black-text opacity_1">
                                                          {" "}
                                                          {med?.hasInsurance ? med?.InsuranceInfo?.ID : med?.rxSense ? rxSenseCard?.memberId :
                                                            state?.memberAssign
                                                              ?.member_id
                                                          }
                                                        </h4>
                                                      </div>
                                                      <div className="mobileView">
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <label>ID</label>
                                                          </div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-sm-12">
                                                            <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                              {med?.hasInsurance ? med?.InsuranceInfo?.ID : med?.rxSense ? rxSenseCard?.memberId :
                                                                state?.memberAssign
                                                                  ?.member_id
                                                              }
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </motion.div>
                    <div className="col-sm-12 col-md-12 col-lg-3 webView showDivonWeb">
                      <div className="medications_inner medications_inner_coupon dlr-Sup "
                      >
                        <div className="row">
                          <div className="col-6 col-sm-6 col-md-12 col-lg-12">
                            <label className="">TOTAL OUT OF POCKET</label>
                            <div className="med-Flex">
                              <h1 className="webView">
                                <sup>
                                  <b>$&nbsp;</b>
                                </sup>
                                {state?.drug?.bestPrice.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}{" "}
                              </h1>
                              <h3 className="mobileView">
                                <sup>
                                  <b>$&nbsp;</b>
                                </sup>
                                {state?.drug?.bestPrice.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2
                                })}{" "}
                              </h3>
                              <p className="font13 flex-end">
                                {Math.round(state?.savingPercentage)}
                                %&nbsp;off{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-6 col-sm-6 col-md-12 col-lg-12 mobileView">
                            <MotionButton>
                              <button className="btn_default" onClick={() => medications()}>
                                Hide coupons
                              </button>
                            </MotionButton>
                          </div>
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            {/* <hr></hr> */}
                            <label className="hrDiv">PHARMACY</label>
                            <div className="review-coupon ">
                              <div className="row ">
                                <div className="col-3 col-sm-3 col-md-3 col-lg-3 autoMargin">
                                  <div className="pharmacyImg_div">
                                    <img
                                      src={searchPharmacyLogo(state?.drug?.pharmacyName)}
                                      className="img-fluid"
                                    />
                                  </div>
                                </div>
                                <div className="col-7 col-sm-7 col-md-7 col-lg- autoMargin">
                                  <div className="">
                                    <p className="shopName">
                                      {" "}
                                      {state?.drug?.pharmacyName}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-sm-3 col-md-3 col-lg-3 best-cheap-offer" >
                                  <OverlayTrigger
                                    delay={{ hide: 100, show: 100 }}
                                    trigger="hover"
                                    ref={tooltipOfferRef}
                                    overlay={(props) => (
                                      <Tooltip {...props}>
                                        {state.cheaperOptionAvailable ? "Cheaper Offer Available" : "Best Offer"}
                                      </Tooltip>
                                    )}
                                    placement="auto"
                                  >
                                    {
                                      state.cheaperOptionAvailable ? <img src={cheaper} style={{ cursor: "pointer" }} />
                                        : <img
                                          src={bestOffer}
                                          style={{ cursor: "pointer" }}
                                        />
                                    }
                                  </OverlayTrigger>
                                </div> */}
                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 autoMargin text-center">
                                  <MotionIcon>
                                    <img
                                      className="cursor-pointer"
                                      src={edit}
                                      onClick={() => goMedications()}
                                    />
                                  </MotionIcon>
                                </div>
                              </div>
                            </div>
                            <div className="row webView">
                              <div className="col-6 col-sm-6 col-md-12 col-lg-12 ">
                                <MotionButton>
                                  <button
                                    className="btn_default"
                                    onClick={() => medications()}
                                  >
                                    Hide coupons
                                  </button>
                                </MotionButton>
                                {/* <hr></hr> */}
                              </div>
                            </div>
                            <h4 className="row mb-2 mobileView">
                              Save and keep your coupons handy
                            </h4>
                            <h4 className="row mb-2 webView">
                              <motion.div className="hrDiv"
                                initial={{ y: -50, marginTop: '0px', paddingTop: '0px' }}
                                animate={{ y: 0, marginTop: '10px', paddingTop: '20px' }}
                                transition={{ duration: 0.5, delay: 0.15 }}
                              >
                                Save your discounts to keep them handy
                              </motion.div>
                            </h4>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <motion.div className="row"
                              initial={{ marginTop: "-0.1rem" }}
                              animate={{ marginTop: "0.5rem" }}
                              transition={{ duration: 0.5, delay: 0.18 }}
                            >
                              <motion.div className="col-6 col-sm-6 col-md-12 col-lg-12"
                                initial={{ y: -50 }}
                                animate={{ y: 0 }}
                                transition={{ duration: 0.6 }}>
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    onClick={() => openSMSpopup(true)}
                                  >
                                    SMS
                                  </button>
                                </MotionButton>
                              </motion.div>
                              <motion.div className="col-6 col-sm-6 col-md-12 col-lg-12"
                                initial={{ y: -50 }}
                                animate={{ y: 0 }}
                                transition={{ duration: 0.5 }}
                              >
                                <MotionButton>
                                  <button
                                    className="btn_success"
                                    onClick={() => openEmailPopup(true)}
                                  >
                                    Email
                                  </button>
                                </MotionButton>
                              </motion.div>
                            </motion.div>
                          </div>
                          {/* <div className="col-12 col-sm-5 col-md-12 col-lg-12">
                            <div className="wallet-img">
                              {isIOS && (
                                <img src={wallet} className="img-fluid" />
                              )}

                              {isAndroid && (
                                <img src={walletGoogle} className="img-fluid" />
                              )}
                            </div>
                          </div> */}
                          <div className="col-sm-12 col-md-12 col-lg-12 ">
                            {/* <hr className=" mobileView"></hr> */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                          <div className="col-12">
                            {windowWidth > 992 &&
                              <div className="offer-section" style={{ marginTop: 8, marginBottom: 10 }}>
                                {cheaperOptionAvailable ?
                                  <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-circle-exclamation" style={{ fontSize: 21 }}></i><span style={{marginLeft: 10}}>Your price may be lower at <a href="javascript:void(0)"                                       onClick={() => goMedications()}
                                  >a different pharmacy.</a></span>
                                  </div>
                                  :
                                  <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-tag" style={{ color: "#40DFB9", fontSize: 26 }}></i><span style={{marginLeft: 10}}>Congratulations! Your pharmacy has the lowest total price for your drugs in your area!</span>
                                  </div>
                                }
                              </div>
                            }
                          </div>
                        </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </section>
          <div className="">
            {pharmacistPara && (
              <div className="row g-0" id="btn-row-back">
                <>
                  <section className=" ">
                    <motion.div className="container" >
                      <div className="row ">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div
                            className="medicine_icon pharma_icon back_icon"
                            onClick={() => IMHere(false)}
                          >
                            <MotionIcon><i className="fas fa-angle-left"></i></MotionIcon>
                          </div>
                        </div>
                      </div>

                      {/*  */}
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="medication-heading instructionHeading">
                              {/* <h2>Instructions for pharmacist</h2> */}
                              <MotionTextH2 text="Instructions for pharmacy staff" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <motion.div className="row  " initial={{ y: "-70vh", opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5, type: "tween" }}>
                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                          <div className="medications_inner m-0 p-0">
                            <div className="row">
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="inst-ff">
                                  <p style={{ paddingBottom: 10, fontSize: 16, color: "rgba(0, 0, 0, 0.7)" }}>
                                    {healthSystem} runs value-based care programs on certain populations of patients and appreciates you processing this savings card.  They will be monitoring the savings your pharmacy brings their members through these discounts. Thank you for your partnership.
                                  </p>
                                  <motion.div className="" initial={{ scale: 0.5 }} animate={{ scale: 1 }} transition={{ duration: 0.5, delay: 0.3, type: "tween" }} >
                                    {state?.medDrugData?.map(
                                      (element, index) => {
                                        return (
                                          <>
                                            <h4 className="weight600  mb-4 ">
                                              {index + 1}. For{" "}
                                              {element?.selectedLabel}
                                            </h4>
                                            <h4 className="mt-3 mb-4 pharmac_inst_h4_mobile font-weig-4">
                                              {element?.hasInsurance ? 'Use with insurance' : 'With RxLink Offer'}
                                            </h4>

                                            {/* <h4 className="mt-3 mb-4 pharmac_inst_h4_mobile font-weig-4">
                                              {element?.description}
                                            </h4> */}
                                            <motion.div className="col-12 col-sm-12 col-md-12 col-lg-12"
                                              initial={{ scaleX: 0 }}
                                              animate={{ opacity: 1, scaleX: 1 }}
                                              transition={{ duration: 0.8, type: 'tween', delay: 0.4 }}
                                            >
                                              <motion.div className="card_info_new "

                                              // whileInView={}
                                              >
                                                <div className="row">
                                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                                                    <h3 className="webView text-start">
                                                      {
                                                        element
                                                          ?.medicationAPIRes
                                                          ?.search_name
                                                      }
                                                    </h3>
                                                    <h4 className="mobileView text-start">
                                                      {
                                                        element
                                                          ?.medicationAPIRes
                                                          ?.search_name
                                                      }
                                                    </h4>
                                                    <p className="mt-2 webView">
                                                      {element?.selectedDosage}{element?.selectedDosage && ", "}
                                                      {element?.selectedQty}{" "}
                                                      {element?.selectedForm}
                                                    </p>
                                                    <h5 className="mt-2 mobileView text-start">
                                                      {element?.selectedDosage}{element?.selectedDosage && ", "}
                                                      {element?.selectedQty}{" "}
                                                      {element?.selectedForm}
                                                    </h5>
                                                  </div>

                                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
                                                    <div className="card_info_new_right">
                                                      {/* <h5>copay assistance</h5> */}
                                                      {element?.bestPrice ? (
                                                        <h3 className="mt-2 card_info__">
                                                          <sup className="sup">
                                                            <b>$&nbsp;</b>
                                                          </sup>
                                                          {element?.bestPrice?.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                          })}
                                                        </h3>
                                                      ) : (
                                                        <h3 className="mt-2 card_info__">
                                                          ---
                                                        </h3>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </motion.div>
                                              <div className="car_info_bottom_new">
                                                <div className="row">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="couponTable">
                                                      <div className="row">
                                                        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                          <div className="webView">
                                                            <label>BIN</label>
                                                            <h4 className="black-text opacity_1">
                                                              {element?.hasInsurance ? element?.InsuranceInfo?.bin : element?.rxSense ? rxSenseCard?.bin :
                                                                state?.memberAssign
                                                                  ?.bin
                                                              }
                                                            </h4>
                                                          </div>
                                                          <div className="mobileView">
                                                            <div className="row">
                                                              <div className="col-sm-12">
                                                                <label>
                                                                  BIN
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <div className="row">
                                                              <div className="col-sm-12">
                                                                <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                  {element?.hasInsurance ? element?.InsuranceInfo?.bin : element?.rxSense ? rxSenseCard?.bin :
                                                                    state?.memberAssign
                                                                      ?.bin
                                                                  }
                                                                </label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                          <div className="webView">
                                                            <label>PCN</label>
                                                            <h4 className="black-text opacity_1">
                                                              {" "}
                                                              {element?.hasInsurance ? element?.InsuranceInfo?.pcn : element?.rxSense ? rxSenseCard?.pcn :
                                                                state?.memberAssign
                                                                  ?.pcn
                                                              }
                                                            </h4>
                                                          </div>
                                                          <div className="mobileView">
                                                            <div className="row">
                                                              <div className="col-sm-12">
                                                                <label>
                                                                  PCN
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <div className="row">
                                                              <div className="col-sm-12">
                                                                <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                  {element?.hasInsurance ? element?.InsuranceInfo?.pcn : element?.rxSense ? rxSenseCard?.pcn :
                                                                    state?.memberAssign
                                                                      ?.pcn
                                                                  }
                                                                </label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                                          <div className="webView">
                                                            <label>GROUP</label>
                                                            <h4 className="black-text opacity_1">
                                                              {" "}
                                                              {
                                                                element?.hasInsurance ? element?.InsuranceInfo?.Group : element?.rxSense ? rxSenseCard?.groupId :
                                                                  state?.memberAssign
                                                                    ?.group_id
                                                              }
                                                            </h4>
                                                          </div>
                                                          <div className="mobileView">
                                                            <div className="row">
                                                              <div className="col-sm-12">
                                                                <label>
                                                                  GROUP
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <div className="row">
                                                              <div className="col-sm-12">
                                                                <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                  {
                                                                    element?.hasInsurance ? element?.InsuranceInfo?.Group : element?.rxSense ? rxSenseCard?.groupId :
                                                                      state?.memberAssign
                                                                        ?.group_id
                                                                  }
                                                                </label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="col-6 col-sm-3 col-md-3 col-lg-3 ">
                                                          <div className="webView">
                                                            <label>ID</label>
                                                            <h4 className="black-text opacity_1">
                                                              {" "}
                                                              {element?.hasInsurance ? element?.InsuranceInfo?.ID : element?.rxSense ? rxSenseCard?.memberId :
                                                                state?.memberAssign
                                                                  ?.member_id
                                                              }
                                                            </h4>
                                                          </div>
                                                          <div className="mobileView">
                                                            <div className="row">
                                                              <div className="col-sm-12">
                                                                <label>
                                                                  ID
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <div className="row">
                                                              <div className="col-sm-12">
                                                                <label className="black-text insrance_card_bottom_label_black opacity_1">
                                                                  {element?.hasInsurance ? element?.InsuranceInfo?.ID : element?.rxSense ? rxSenseCard?.memberId :
                                                                    state?.memberAssign
                                                                      ?.member_id
                                                                  }
                                                                </label>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </motion.div>
                                          </>
                                        );
                                      }
                                    )}
                                  </motion.div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </motion.div>
                  </section>
                </>
              </div>
            )}
          </div>

          <Modal
            show={isVisibleSMSModal}
            onHide={() => setIsVisibleSMSModal(false)}
            keyboard={false}
            centered
          >
            <Modal.Body>
              <section>
                <div className="container">
                  <form id="loginID">
                    <div className="forget_box">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <p>Are you sure you want to active SMS</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 col-md-4 col-lg-4">
                          <MotionButton>
                            <button
                              type="button"
                              className="btn btn-primary forget_btn"
                              onClick={() => sendSMS(true)}
                            >
                              Yes
                            </button>
                          </MotionButton>
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4">
                          <MotionButton>
                            <button
                              type="button"
                              className="btn btn-primary forget_btn"
                              onClick={() => setIsVisibleSMSModal(false)}
                            >
                              No
                            </button>
                          </MotionButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </Modal.Body>
          </Modal>

          <Modal
            show={isComingSoon}
            onHide={() => setIsComingSoon(false)}
            keyboard={false}
            centered
          >
            <Modal.Body>
              <section>
                <div className="container">
                  <form id="loginID">
                    <div className="forget_box row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <p className="text-center">Coming soon!</p>
                      </div>
                      <div className="row">
                        <div className="center">
                          <MotionButton>
                            <button
                              type="button"
                              className="btn btn-primary commingSoonBtn"
                              onClick={() => setIsComingSoon(false)}
                            >
                              OK
                            </button>
                          </MotionButton>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </Modal.Body>
            {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
          </Modal>

          <SendSMSOrEmailModalWeb
            isOpen={isSMS || isEmail || isEmailOrSMSSuccess}
            formik={formik}
            handleClose={() => closeSMSOrEmailPopup(false)}
            isSMS={isSMS}
            sendEmail={() => sendEmail(true)}
            sendSMS={formik.handleSubmit}
            validateEmail={validateEmail}
            emailError={emailError}
            hasSuccess={isEmailOrSMSSuccess}
            termsHandler={termsHandler}
            termsError={termsError}
          />
        </>
      )}
    </>
  );
};

export default CouponCard;