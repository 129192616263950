import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { httpClient, httpClientJava } from "../environment";

const initialState = {
   patientInfo: {}
};

export const addOrUpdatePatient = createAsyncThunk("PatientInfo", (body) =>
  httpClientJava
    .post(`clinician/addOrUpdatePatient`, body)
    .then(({ data }) => {
      return data;
    })
    .catch(error => error)
);

export const deletePatient = createAsyncThunk("deletePatient", (body) =>
  httpClientJava
    .post(`clinician/removePatientSavings`, body)
    .then(({ data }) => {
      return data;
    })
    .catch(error => error)
);


export const patientDetail = createSlice({
  name: "PatientInfo",
  initialState,
  reducers: {
    setPatientDetail: (state, action) => {
      state.patientInfo = action.payload;
    },
    [addOrUpdatePatient.pending.type]: (state, action) => {
      state.patientInfo = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [addOrUpdatePatient.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
      state.patientInfo = {
        status: "idle",
        data: action.payload,
        error: {},
      };
    },
    [addOrUpdatePatient.rejected.type]: (state, action) => {
      state.patientInfo = {
        status: "idle",
        data: {},
        error: action.payload,
      };
    },
  },
});

export const { setPatientDetail } = patientDetail.actions;

export default patientDetail.reducer;
