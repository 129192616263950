import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { saveInsurance } from "../../reducers/submitInsurance";
import "react-toastify/dist/ReactToastify.css";
import Header from "../partials/Header";
import {
  validateOTP,
  generateOtp,
  otpExpiredApi,
} from "../../reducers/validateOtp";
// import Vector from "../../assets/images/tab-otp.svg";
import Vector from "../../assets/images/provider-onboarding1.png"
import { userSignUp } from "../../reducers/signUp";
import { motion } from "framer-motion";
import { MotionButton } from "../motion/MotionButton";
import { successToast, errorToast } from "../toast/toast";
import { useWindowWidth } from "../searchPharmacy/useWindowWidth";

export default ({ setIp }) => {
  const [insurance, setInsurance] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputOTP, setInputOTP] = useState("");
  const [ssn_1, setSsn_1] = useState("");
  const [ssn_2, setSsn_2] = useState("");
  const [ssn_3, setSsn_3] = useState("");
  const [ssn_4, setSsn_4] = useState("");
  const [ssn_5, setSsn_5] = useState("");
  const [ssn_6, setSsn_6] = useState("");
  const userName = JSON.parse(localStorage.getItem("user"));
 // const userBody = JSON.parse(localStorage.getItem("userBody"));
  const [myMessage, setMessage] = useState("MMMMM");
  const { windowWidth } = useWindowWidth();


  useEffect(() => {
    // alert("OTP Expired Resent again ?")
    // call function after every 10 min
    setInterval(otpExpired, 600000);
    // call function once after 10 min
    setTimeout(function () {
      otpExpired();
    }, 600000);
  }, []);

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    if (name === "ssn-1") {
      setSsn_1(value);
    }
    if (name === "ssn-2") {
      setSsn_2(value);
    }
    if (name === "ssn-3") {
      setSsn_3(value);
    }
    if (name === "ssn-4") {
      setSsn_4(value);
    }
    if (name === "ssn-5") {
      setSsn_5(value);
    }
    if (name === "ssn-6") {
      setSsn_6(value);
    }
    // let concatenate = ssn_1 + ssn_2  + ssn_3  + ssn_4  + ssn_5  + ssn_6;
    // setInputOTP(concatenate);
    const [fieldName, fieldIndex] = name.split("-");
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
        );
        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  const resetOtp = () => {
    // document.querySelector(`input[name=ssn-1]`).value = "";
    // document.querySelector(`input[name=ssn-2]`).value = "";
    // document.querySelector(`input[name=ssn-3]`).value = "";
    // document.querySelector(`input[name=ssn-4]`).value = "";
    // document.querySelector(`input[name=ssn-5]`).value = "";
    // document.querySelector(`input[name=ssn-6]`).value = "";
    setSsn_1("");
    setSsn_2("");
    setSsn_3("");
    setSsn_4("");
    setSsn_5("");
    setSsn_6("");
  };

  const validateOtp = () => {
    let concatenate = ssn_1 + ssn_2 + ssn_3 + ssn_4 + ssn_5 + ssn_6;

    //const userName =  JSON.parse(localStorage.getItem("user"));
    if (concatenate.length == 0) {
      errorToast("OTP is required");
      return;
    }
    if (concatenate.length < 6) {
      errorToast("Valid OTP is required");
      return;
    }
    setLoading(true);
    let body = {
      username: userName?.username,
      otp: concatenate,
    };
    dispatch(validateOTP(body))
    .unwrap()
    .then((response) => {
    
      setLoading(false);

      //localStorage.setItem("user", JSON.stringify(response?.data));
     
      if (response.data != null) {
      
        successToast("OTP validated.");
        navigate('/patient');
      } else {
        setLoading(false);
        errorToast(response?.message);
        resetOtp();
      }
    })
    .catch((e) => {
      console.log(e);
    });

     
  };

  const resendOtp = () => {
    resetOtp();
    let body = {
      username: userName?.username,
    };
    dispatch(generateOtp(body))
      .unwrap()
      .then((response) => {
        // window.location.reload();
        setInputOTP(null);
        if (response?.message) {
          successToast(response?.message);
        }
        if (response?.statusCode >= 200 && response?.statusCode <= 299) {
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const otpExpired = () => {
    setLoading(true);

    let body = {
      username: userName,
    };
    dispatch(otpExpiredApi(body))
      .unwrap()
      .then((response) => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getSectionClass = windowWidth > 991 ? "fadeAni cont-style" : "fadeAni";
  


  return (
    <>
      <section className={getSectionClass}>
        {windowWidth < 991 && <div className="row g-0">
          <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div className="">&nbsp;</div>
          </div>
        </div>}
        <div className="row g-0">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0 autoMargin">
            <div className="vector-image">
              <motion.img
                key={Vector}
                // src={image.src}
                initial={{ opacity: 0, x: 500, }}
                animate={{ opacity: 1, x: 0, }}
                exit={{ opacity: 0 }}
                // src={tabHealth} 
                transition={{ duration: 0.8, type: 'tween', }}
                src={Vector} 
                className="img-fluid" 
                />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 paddingZero" style={{ zIndex: 100 }}>
            <div className="" >
              <motion.div className="insurance_inner_main"
                initial={{ scale: 0.8, }}
                animate={{ scale: 1 }}
                transition={{ duration: .5 }}
              >
                <form id="otpID">
                  <div className="row" id="cc-info-div">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <motion.div className="insurance_inner"
                        initial={{ opacity: 0, }}
                        animate={{ opacity: 1, }}
                        transition={{ duration: 0.7 }}
                      >
                        <div className="row webView">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="page_counter" style={{paddingBottom: 20, marginTop: -30}}>
                              <motion.h3 className="active"
                                initial={{ scale: 0.5, }}
                                animate={{ scale: 1 }}
                                transition={{ duration: 1 }}
                              >1</motion.h3>
                              <h4>2</h4>
                              <h4>3</h4>
                            </div>
                          </div>
                        </div>
                        <motion.div
                          className="row"
                          initial={{ x: 300, }}
                          animate={{ x: 0 }}
                          transition={{ duration: 0.8 }}
                        >
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <h1>Let’s verify your email</h1>
                          </div>
                        </motion.div>
                        <motion.div className="row"
                          initial={{ x: 300, }}
                          animate={{ x: 0 }}
                          transition={{ duration: 0.8, delay: 0.15 }}
                        >
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <p>
                              Enter the 6 digit code we just sent you via
                              email. After verification, you’ll be able to use
                              your email to access your account
                            </p>
                          </div>
                        </motion.div>
                        <motion.div className="row"
                          initial={{ x: 300, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{ duration: 0.8, delay: 0.25 }}
                        >
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="numberVerify">
                              <input
                                type="number"
                                pattern="[0-9]"
                                inputmode="numeric"
                                className="form-control  custom-form-control"
                                placeholder="8"
                                name="ssn-1"
                                value={ssn_1}
                                maxLength="1"
                                onChange={handleChange}
                                autocomplete="one-time-code"
                                max={9}
                              />
                              <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                className="form-control  custom-form-control"
                                placeholder="8"
                                name="ssn-2"
                                value={ssn_2}
                                maxLength={1}
                                onChange={handleChange}
                                autocomplete="one-time-code"
                                max={9}
                              />
                              <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                className="form-control  custom-form-control"
                                placeholder="8"
                                name="ssn-3"
                                value={ssn_3}
                                maxLength={1}
                                onChange={handleChange}
                                autocomplete="one-time-code"
                                max={9}
                              />
                              <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                className="form-control  custom-form-control"
                                placeholder="8"
                                name="ssn-4"
                                value={ssn_4}
                                maxLength={1}
                                onChange={handleChange}
                                autocomplete="one-time-code"
                                max={9}
                              />
                              <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                className="form-control  custom-form-control"
                                placeholder="8"
                                name="ssn-5"
                                value={ssn_5}
                                maxLength={1}
                                onChange={handleChange}
                                autocomplete="one-time-code"
                                max={9}
                              />
                              <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                className="form-control  custom-form-control"
                                placeholder="8"
                                name="ssn-6"
                                value={ssn_6}
                                maxLength={1}
                                max={9}
                                onChange={handleChange}
                                autocomplete="one-time-code"
                              />
                            </div>
                          </div>
                        </motion.div>
                        {/* <motion.div
                          className="button_div  insurance_inner_main_btn mt-3 g-0"
                          id="cc-info-div-smbt"
                          initial={{ x: 300 }}
                          animate={{ x: 0 }}
                          transition={{ duration: 0.7 }}
                        >
                          <motion.div className="margin-right "
                          // initial={{ x: 300, opacity: 0 }}
                          // animate={{ x: 0, opacity: 1 }}
                          // transition={{ duration: 12, delay: 0.25 }}
                          >
                            <MotionButton>
                              <button
                                className="btn_success"
                                type="button"
                                onClick={() => validateOtp()}
                              >
                                Submit
                              </button>
                            </MotionButton>
                          </motion.div>
                          <div className="margin-right ">
                            <MotionButton>
                              <button
                                className="btn_default"
                                type="button"
                                onClick={() => resendOtp()}
                              >
                                Resend Code
                              </button>
                            </MotionButton>
                          </div>
                        </motion.div> */}
                      </motion.div>
                      <motion.div
                        className="button_div  insurance_inner_main_btn2 g-0"
                        id="cc-info-div-smbt"
                        initial={{ x: 300 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 0.7 }}
                      >
                        <div className="margin-right ">
                          <MotionButton>
                            <button
                              className="btn_success"
                              type="button"
                              disabled={loading}
                              onClick={() => validateOtp()}
                            >
                              Submit
                            </button>
                          </MotionButton>
                        </div>
                        <div className="margin-right ">
                          <MotionButton>
                            <button
                              className="btn_default"
                              type="button"
                              onClick={() => resendOtp()}
                            >
                              Resend Code
                            </button>
                          </MotionButton>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </form>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="mobileView" style={{position: "absolute", left: "43%", bottom: 0}}>
          <div className="row g-0">
            <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
              <div className="dot-main">
                <span className="dot dotActive"></span>
                <span className="dot"></span>
                <span className="dot "></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
